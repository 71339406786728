import React, { Component } from 'react';
import { Col, Skeleton, Row, Collapse, Input, message, Icon } from 'antd';
import connect from 'react-redux/es/connect/connect';
import moment from 'moment';
import changeCase from 'change-case';
import _ from 'lodash';

import * as OrderActions from '../../../actions/order';
import * as OrderApis from '../../../apis/order';

import LayoutWrapper from '../../../components/utility/layoutWrapper';
import InspectionDetailPageWrapper from '../styles/inspection-detail-page.style';
import PageHeader from '../../../components/utility/pageHeader';
import basicStyle from '../../../settings/basicStyle';
import InspectionStepsDropdown from '../../../components/order/inspection/inspection-steps-dropdown';
import Box from '../../../components/utility/box';

const { rowStyle, colStyle, gutter } = basicStyle;
const { Panel } = Collapse;
const { TextArea } = Input;

class InspectionSummaryPage extends Component {
	state = {};

	componentDidMount() {
		this.fetchOrder();
	}

	fetchOrder = (callback) => {
		const { match, dispatch } = this.props;
		dispatch(
			OrderActions.fetchOrderById(match.params.orderId, {}, (order) => {
				this.setState({
					selectedInspection : order.inspections[0],
					selectedOrder : order
				});

				if (callback) {
					callback(order);
				}
			})
		);
	};

	renderBasicDetails = () => {
		const { selectedOrder, selectedInspection } = this.state;

		const inspectionStartTime = moment(
			selectedInspection.inspectionStartTime
		).format('LT');

		const inspectionEndTime = selectedInspection.inspectionEndTime
			? moment(selectedInspection.inspectionEndTime).format('LT')
			: 'N/A';

		return (
			<div className="basic-detail-container">
				<Row style={rowStyle} gutter={gutter}>
					<Col lg={4} style={colStyle}>
						<span className="title">Attendees</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="data">
							Inspacco Team, {selectedInspection.attendees.join(',')}
						</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="title">Inspection Dates</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="data">
							{`${inspectionStartTime} - ${inspectionEndTime}`}
						</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="title">House Type</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="data">{selectedOrder.property.subType}</span>
					</Col>
				</Row>

				<Row style={rowStyle} gutter={gutter}>
					<Col lg={4} style={colStyle}>
						<span className="title">Occupied</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="data">
							{selectedInspection.isPropertyOccupied ? 'Yes' : 'No'}
						</span>
					</Col>

					<Col lg={4} style={colStyle}>
						<span className="title">Main Entrance</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="data">
							{selectedInspection.mainEntranceDirection
								? changeCase.titleCase(selectedInspection.mainEntranceDirection)
								: 'N/A'}
						</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="title">House Area</span>
					</Col>
					<Col lg={4} style={colStyle}>
						<span className="data">-</span>
					</Col>
				</Row>
			</div>
		);
	};

	updateInspection = async (obj, callback) => {
		try {
			const { selectedOrder, selectedInspection } = _.cloneDeep(this.state);
			await OrderApis.updateInspection(
				selectedOrder._id,
				selectedInspection._id,
				obj
			);
			message.success('Inspection updated');
			if (callback) {
				return callback();
			}
		} catch (err) {
			console.log(err);
		}
	};

	getSummaryExtraIcon = (description) => {
		if (!description || description.length < 50) {
			return <Icon type="close-circle" />;
		}

		return <Icon type="check-circle" />;
	};

	renderMainSummary = () => {
		const { selectedInspection } = _.cloneDeep(this.state);

		return (
			<div>
				<TextArea
					value={selectedInspection.summary.mainDescription}
					key="main-summary"
					name="main-summary"
					onChange={(e) => {
						selectedInspection.summary.mainDescription = e.target.value;
						this.setState({ selectedInspection });
					}}
					onBlur={() => {
						this.updateInspection({ summary : selectedInspection.summary });
					}}
				/>
			</div>
		);
	};

	renderCategorySummary = (category) => {
		const { selectedInspection } = _.cloneDeep(this.state);

		return (
			<div>
				<TextArea
					value={category.description}
					key={`category-summary-${category._id}`}
					onChange={(e) => {
						const currentCategory = _.find(
							selectedInspection.summary.categories,
							input => input._id === category._id
						);

						currentCategory.description = e.target.value;
						this.setState({ selectedInspection });
					}}
					onBlur={() => {
						this.updateInspection({ summary : selectedInspection.summary });
					}}
				/>
			</div>
		);
	};

	getShortedSummaryForCategories = categories => _.minBy(categories, category => (category.description ? category.description.length : 0));

	renderSummary = () => {
		const { selectedInspection } = this.state;
		const { summary } = selectedInspection;
		const categoryShortestSummary = this.getShortedSummaryForCategories(
			summary.categories
		);

		return (
			<div className="detailed-summary-container">
				<Collapse
					expandIconPosition="left"
					defaultActiveKey={['category-summary']}
				>
					<Panel
						header="Main Summary"
						extra={this.getSummaryExtraIcon(summary.mainDescription)}
						key="main-summary"
					>
						{this.renderMainSummary()}
					</Panel>
					<Panel
						header="Category Summary"
						extra={this.getSummaryExtraIcon(
							categoryShortestSummary.description
						)}
						key="category-summary"
					>
						<Collapse expandIconPosition="left">
							{summary.categories.map((category) => {
								const { description } = category;

								return (
									<Panel
										header={category.title}
										extra={this.getSummaryExtraIcon(description)}
									>
										{this.renderCategorySummary(category)}
									</Panel>
								);
							})}
						</Collapse>
					</Panel>
				</Collapse>
			</div>
		);
	};

	render() {
		const { match, history } = this.props;
		const { selectedOrder } = this.state;

		return (
			<LayoutWrapper>
				<InspectionDetailPageWrapper>
					<div className="summary-container">
						<PageHeader>Inspection Summary</PageHeader>
						<InspectionStepsDropdown
							currentStep="summary"
							orderId={match.params.orderId}
							inspectionId={match.params.inspectionId}
							history={history}
							selectedOrder={selectedOrder}
						/>

						<div>
							<Box title="Basic Details">
								<Skeleton loading={!selectedOrder} active>
									{selectedOrder && this.renderBasicDetails()}
								</Skeleton>
							</Box>
						</div>

						<div>
							<Box title="Summary">
								<Skeleton loading={!selectedOrder} active>
									{selectedOrder && this.renderSummary()}
								</Skeleton>
							</Box>
						</div>
					</div>
				</InspectionDetailPageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(InspectionSummaryPage);
