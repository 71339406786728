import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const InspectionDetailPageWrapper = styled.div`
	width: 100%;
	.isoBoxWrapper {
		margin-bottom: 1em;
	}

	.summary-container {
		.basic-detail-container {
			.ant-row {
				margin-top: 1em;
			}

			.ant-col {
				padding: 1em;
				border: 1px solid gray;
			}

			.title {
				font-weight: bold;
			}
		}

		.detailed-summary-container {
			.anticon {
				font-size: 1.5em;
			}
			.anticon-check-circle {
				color: green;
			}

			.anticon-close-circle {
				color: red;
			}
		}
	}

	.snapshot-container {
		.all-count-table-container {
			display: flex;
			.ant-table-wrapper {
				width: 100%;
			}
		}
	}

	.blocks-outer-container {
		.block-container {
			.category-overall-status {
			}
		}
	}
`;

export default WithDirection(InspectionDetailPageWrapper);
