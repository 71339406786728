import React, { Component } from 'react';
import { Drawer } from 'antd';

import InspectionItemCard from './inspection-image-card';

class InspectionImageEditorDrawer extends Component {
	render() {
		const {
			onCancel,
			selectedItem,
			selectedRoom,
			selectedCategory,
			onDeleteImage,
			onUpdateImageCaption,
			onImageClick,
			imageType,
			showCaption
		} = this.props;
		if (!selectedRoom) {
			return null;
		}
		const title = imageType === "room" ? selectedRoom.name : `${selectedRoom.name} -> ${selectedCategory.name} -> ${selectedItem.name}`;
		const selectedResource = imageType === "room" ? selectedRoom : selectedItem;

		return (
			<Drawer
				onClose={onCancel}
				footer={null}
				visible={true}
				width={400}
				title={title}
			>
				{selectedResource.attachments.map(attachment => (
					<div
						style={{ marginTop : '1em' }}
						key={`${attachment._id}-div-container`}
					>
						<InspectionItemCard
							attachment={attachment}
							onDeleteImage={(_id) => {
								onDeleteImage(_id);
							}}
							showCaption={showCaption}
							onUpdateImageCaption={(_id, caption) => {
								onUpdateImageCaption(_id, caption);
							}}
							onImageClick={onImageClick}
						/>
					</div>
				))}
			</Drawer>
		);
	}
}

export default InspectionImageEditorDrawer;
