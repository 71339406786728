import React from 'react';
import { Col, Row, Steps } from 'antd';
import changeCase from 'change-case';
import _ from 'lodash';

import basicStyle from '../../../settings/basicStyle';

const { rowStyle, colStyle, gutter } = basicStyle;
const { Step } = Steps;

const non_cancelled_statuses = [
	{ id : 'pending', step : 0, description : 'Received Order' },
	{ id : 'approved', step : 1, description : 'Inspector Assigned' },
	{ id : 'processing', step : 2, description : 'Inspection Started' },
	{ id : 'pending_report', step : 3, description : 'Report Upload Pending' },
	{ id : 'completed', step : 4, description : 'Completed' }
];

const cancelled_statuses = [
	{ id : 'pending', step : 0, description : 'Received Order' },
	{ id : 'cancelled', step : 1, description : 'Cancelled' }
];

export default (props) => {
	const { selectedOrder } = props;
	if (!selectedOrder) {
		return null;
	}

	const steps =		selectedOrder.status !== 'cancelled'
		? non_cancelled_statuses
		: cancelled_statuses;

	let currentStep = _.find(steps, step => step.id === selectedOrder.status);

	if (!currentStep) {
		currentStep = steps[0];
	}

	return (
		<Row style={rowStyle} gutter={gutter}>
			<Col xs={24} style={colStyle}>
				<Steps current={currentStep.step}>
					{steps.map((step, index) => (
						<Step
							title={changeCase.titleCase(step.id)}
							description={step.description}
							key={index}
						/>
					))}
				</Steps>
				,
			</Col>
		</Row>
	);
};
