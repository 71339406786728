import React, { Component } from 'react';
import { Row, Col, Select, DatePicker, Input, message, Button } from 'antd';
import moment from 'moment';
import basicStyle from '../../../settings/basicStyle';
import OrderDetailWrapper from '../styles/order-detail.style';

const { rowStyle, colStyle, gutter } = basicStyle;
const { Option } = Select;

class OrderDetailOther extends Component {
	render() {
		const {
			selectedOrder,
			inspectors = [],
			onInspectorAssign,
			updateInProgress,
			updateOrderDetails,
			onFieldValueChange,
			onShowInspectionDetails,
			onBrokerChangeClick
		} = this.props;

		if (!selectedOrder) {
			return null;
		}

		const isProcessingFinished = ['pending', 'approved', 'processing'].indexOf(selectedOrder.status) === -1;
		const isFinalState = ['completed', 'cancelled'].indexOf(selectedOrder.status) !== -1;
		const brokerName = selectedOrder.leadGenerator
			&& selectedOrder.leadGeneratorType === 'broker'
			? selectedOrder.leadGenerator.name
			: null;

		return (
			<OrderDetailWrapper>
				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Inspector</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<Select
							placeholder="Assign Inspector"
							showSearch
							filterOption={(input, option) => {
								console.log(option.props.children);
								return option.props.children
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0;
							}}
							onChange={(e) => {
								onInspectorAssign(e);
							}}
							value={
								selectedOrder.inspector
									? selectedOrder.inspector._id || selectedOrder.inspector
									: undefined
							}
							loading={updateInProgress}
							disabled={isProcessingFinished}
						>
							{inspectors.map(inspector => (
								<Option key={inspector._id} value={inspector._id}>
									{`${inspector.name} (${inspector.phone})`}
								</Option>
							))}
						</Select>
					</Col>
				</Row>
				<hr />

				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Inspection Date</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<DatePicker
							showTime={{ format : 'hh:mm a', use12Hours : true }}
							placeholder="Select Time"
							format="YYYY-MM-DD hh:mm a"
							value={moment(selectedOrder.executionDate)}
							onChange={(value) => {
								if (value) {
									onFieldValueChange({
										executionDate : value.toISOString(true)
									});
								}
							}}
							onOk={() => {
								updateOrderDetails({
									executionDate : selectedOrder.executionDate
								});
							}}
							disabled={isProcessingFinished}
						/>
					</Col>
				</Row>
				<hr />

				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Order Amount</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<Input
							type="number"
							value={selectedOrder.orderAmount}
							onChange={(e) => {
								onFieldValueChange({ orderAmount : e.target.value });
							}}
							onBlur={() => {
								if (!selectedOrder.orderAmount) {
									message.error('Order amount must be valid');
									return;
								}
								updateOrderDetails({
									orderAmount : parseFloat(selectedOrder.orderAmount)
								});
							}}
							disabled={isFinalState}
						/>
					</Col>
				</Row>
				<hr />

				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Amount Collected</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<Input
							type="number"
							value={selectedOrder.amountCollected}
							onChange={(e) => {
								onFieldValueChange({ amountCollected : e.target.value });
							}}
							onBlur={() => {
								if (!selectedOrder.amountCollected) {
									message.error('Amount must be valid');
									return;
								}
								updateOrderDetails({
									amountCollected : parseFloat(selectedOrder.amountCollected)
								});
							}}
							disabled={isFinalState}
						/>
					</Col>
				</Row>
				<hr />

				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Broker</span>
					</Col>
					<Col xs={24} md={8} style={colStyle} className="body">
						<span>{brokerName || 'N/A'}</span>
					</Col>
					<Col xs={24} md={8} style={colStyle} className="body">
						<Button
							onClick={onBrokerChangeClick}
							disabled={isFinalState}
						>
							{brokerName ? 'Change Broker' : 'Assign Broker'}
						</Button>
					</Col>
				</Row>
				<hr />

				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} style={colStyle} className="title">
						<Button
							type="primary"
							block
							disabled={selectedOrder.inspections.length === 0}
							onClick={onShowInspectionDetails}
						>
							Show Inspection Details
						</Button>
					</Col>
				</Row>
			</OrderDetailWrapper>
		);
	}
}

export default OrderDetailOther;
