import React from 'react';
import { Col, Row, Select } from 'antd';
import basicStyle from '../../../settings/basicStyle';
import Box from '../../utility/box';
import _ from 'lodash';

const { Option } = Select;
const { rowStyle, colStyle, gutter } = basicStyle;

export default (props) => {
	const { currentStep, orderId, inspectionId, history, selectedOrder } = props;

	return (
		<div>
			<div>
				<Box>
					<Row style={rowStyle} gutter={gutter}>
						<Col md={2} xs={12} style={colStyle}>
							<h3>Jump To</h3>
						</Col>
						<Col md={4} xs={12} style={colStyle}>
							<Select
								defaultValue="summary"
								value={currentStep}
								onChange={(value) => {
									history.push(
										`/orders/${orderId}/inspections/${inspectionId}/${value}`
									);
								}}
							>
								<Option value="summary">Summary</Option>
								<Option value="snapshot">Snapshot</Option>
								<Option value="rooms">Rooms</Option>
							</Select>
						</Col>
						{selectedOrder && selectedOrder.status === 'processing' && (
							<Col
								md={8}
								xs={24}
								style={_.merge({}, { color : 'red' }, colStyle)}
							>
								Inspection in progress, avoid any updates..!
							</Col>
						)}
					</Row>
				</Box>
			</div>
		</div>
	);
};
