import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const CategoriesListPageWrapper = styled.div`
	width: 100%;
	.search-box {
		margin-bottom: 1em;
		text-align: right;
	}
`;

export default WithDirection(CategoriesListPageWrapper);
