const config = {
	403 : {
		img : 'https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg',
		title : '403',
		desc : "Sorry, you don't have permission to view this page"
	},
	404 : {
		img : 'https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg',
		title : '404',
		desc : 'Sorry, the page you visited does not exist'
	},
	500 : {
		img : 'https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg',
		title : '500',
		desc : 'Sorry, the server is reporting an error'
	}
};

export default config;
