import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Table, message } from 'antd';
import _ from 'lodash';

import LayoutWrapper from '../../components/utility/layoutWrapper';
import PageHeader from '../../components/utility/pageHeader';
import Box from '../../components/utility/box';
import UserSearchBox from '../../components/user/user-search';

import * as UserHeaders from '../../utils/table-headers/user-list';
import UserEditModal from '../../components/user/user-edit-modal';
import UserListPageWrapper from './styles/users-list-page.style';
//Actions
import * as UserActions from '../../actions/user';
import * as SettingActions from '../../actions/setting';

//Apis
import * as UsersApis from '../../apis/user';

class UsersListPage extends Component {
	state = {
		showAddEditModal : false,
		updateInProgress : false,
		usersLoading : false,
		search : {}
	};

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(SettingActions.fetchEnums());
		dispatch(SettingActions.fetchCities());
		this.fetchUsers(0);
	}

	componentDidUpdate(prevProps, prevState) {
		//When selected user already has area in address;
		const { selectedUser } = this.state;
		const { selectedUser : prevUser } = prevState;

		if (
			!prevUser
			&& selectedUser
			&& selectedUser.address
			&& selectedUser.address.city
		) {
			this.fetchCityAreas(selectedUser.address.city);
		}
	}

	fetchCityAreas = (city) => {
		console.log('City changed ', city);
		const { dispatch } = this.props;
		dispatch(SettingActions.fetchCityAreas(city));
	};

	fetchUsers = (page) => {
		this.setState({ usersLoading : true });
		const { dispatch } = this.props;
		const { search } = this.state;

		dispatch(
			UserActions.fetchUsers({ page, limit : 10, ...search }, () => {
				this.setState({ usersLoading : false });
				console.log('All users fetched');
			})
		);
	};

	onUserAddClick = () => {
		this.setState({
			selectedUser : {
				active : true
			},
			showAddEditModal : true
		});
	};

	onUserEditClick = (selectedUser) => {
		this.setState({ selectedUser, showAddEditModal : true });
	};

	onSearchValueChange = (obj) => {
		const { search } = this.state;
		this.setState({ search : _.merge({}, search, obj) });
	};

	onSearchSubmit = () => {
		this.fetchUsers(0);
	};

	onFieldValueChange = (obj) => {
		console.log(obj);
		const { selectedUser } = _.cloneDeep(this.state);
		this.setState({
			selectedUser : _.assign({}, selectedUser, obj)
		});
	};

	onAddressChange = (obj) => {
		const { selectedUser } = _.cloneDeep(this.state);
		if (!selectedUser.address) {
			selectedUser.address = {};
		}

		selectedUser.address = _.merge({}, selectedUser.address, obj);

		this.setState({ selectedUser });

		if (obj.city) {
			this.fetchCityAreas(obj.city);
		}
	};

	onAddressClear = () => {
		const { selectedUser } = _.cloneDeep(this.state);
		selectedUser.address = undefined;
		this.setState({ selectedUser });
	};

	onSubmitUser = async () => {
		const { selectedUser } = this.state;
		console.log(selectedUser);
		this.setState({ updateInProgress : true });

		try {
			if (selectedUser._id) {
				await UsersApis.updateUser(selectedUser);
			} else {
				await UsersApis.createUser(selectedUser);
			}
			message.success('User saved!');
			this.setState({ updateInProgress : false, showAddEditModal : false });
			this.fetchUsers();
		} catch (err) {
			console.log(err);
			this.setState({ updateInProgress : false });
		}
	};

	render() {
		const {
			showAddEditModal,
			selectedUser,
			updateInProgress,
			usersLoading,
			search
		} = this.state;
		const { user, setting } = this.props;
		const { users, count, limit, page } = user.allUsers;
		const { cities, areas } = setting;

		return (
			<LayoutWrapper>
				<UserListPageWrapper>
					<PageHeader>Manage Users</PageHeader>
					<div style={{ marginBottom : '1em' }}>
						<Box title="Search">
							<UserSearchBox
								{...search}
								onSearchValueChange={this.onSearchValueChange}
								onSearchSubmit={this.onSearchSubmit}
								onUserAdd={this.onUserAddClick}
								allRoles={setting.enums.ROLES || []}
							/>
						</Box>
					</div>

					<Box>
						<Table
							scroll={{ x : 1200 }}
							columns={UserHeaders.getDefaultHeaders(this.onUserEditClick)}
							dataSource={users}
							loading={usersLoading}
							pagination={{
								current : page + 1,
								pageSize : limit,
								total : count
							}}
							onChange={(currentPagination) => {
								this.fetchUsers(currentPagination.current - 1);
							}}
						/>
					</Box>
					{showAddEditModal && (
						<UserEditModal
							selectedUser={selectedUser}
							onCancel={() => {
								this.setState({ showAddEditModal : false });
							}}
							onFieldValueChange={this.onFieldValueChange}
							onAddressChange={this.onAddressChange}
							onAddressClear={this.onAddressClear}
							onSubmit={this.onSubmitUser}
							loading={updateInProgress}
							allRoles={setting.enums.ROLES || []}
							areas={areas}
							cities={cities}
						/>
					)}
				</UserListPageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(UsersListPage);
