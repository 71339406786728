import React, { Component } from 'react';
import { Form, Input, DatePicker, Select } from 'antd';
const { Option } = Select;

const formItemLayout = {
	labelCol : {
		xs : { span : 24 }
	},
	wrapperCol : {
		xs : { span : 24 }
	}
};

class OrderCreateOtherDetailsForm extends Component {
	renderPropertyBuyType = () => {
		const { propertyBuyTypes, onOtherDetailsChange } = this.props;

		return (
			<Select
				style={{ width : '100%' }}
				placeholder="Please select"
				onChange={(e) => {
					onOtherDetailsChange({ propertyBuyType : e });
				}}
			>
				{propertyBuyTypes.map(subtype => (
					<Option key={subtype}>{subtype.toUpperCase()}</Option>
				))}
			</Select>
		);
	};

	renderInspectionType = () => {
		const { inspectionTypes, onOtherDetailsChange } = this.props;

		return (
			<Select
				style={{ width : '100%' }}
				placeholder="Please select"
				onChange={(e) => {
					onOtherDetailsChange({ inspectionType : e });
				}}
			>
				{inspectionTypes.map(subtype => (
					<Option key={subtype}>{subtype.toUpperCase()}</Option>
				))}
			</Select>
		);
	};

	render() {
		const { form, onSubmit, onOtherDetailsChange } = this.props;
		const { getFieldDecorator } = form;

		return (
			<Form onSubmit={onSubmit}>
				<Form.Item {...formItemLayout} label="Property Buy Type">
					{getFieldDecorator('propertyType', {
						rules : [

						]
					})(this.renderPropertyBuyType())}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Inspection Type">
					{getFieldDecorator('inspectionType', {
						rules : [

						]
					})(this.renderInspectionType())}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Order Amount">
					{getFieldDecorator('orderAmount', {
						rules : [
							{
								required : true,
								message : 'Please input Order Amount!'
							}
						]
					})(
						<Input
							id="orderAmount"
							name="orderAmount"
							placeholder="Valid Order Amount"
							type="number"
							onChange={(e) => {
								onOtherDetailsChange({
									orderAmount : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Inspection Date">
					{getFieldDecorator('executionDate', {
						rules : [
							{
								required : true,
								message : 'Please input Inspection Date!'
							}
						]
					})(
						<DatePicker
							showTime={{ format : 'hh:mm a', use12Hours : true }}
							placeholder="Select Time"
							format="YYYY-MM-DD hh:mm a"
							onChange={(value) => {
								onOtherDetailsChange({
									executionDate : value.toISOString(true)
								});
							}}
						/>
					)}
				</Form.Item>
			</Form>
		);
	}
}

export default OrderCreateOtherDetailsForm;
