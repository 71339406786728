const firebase = require('firebase/app');
require('firebase/auth');

let config = {
	apiKey : 'AIzaSyCGAM8x-L0t3QsyRPUJfSbpMLh9o1c5e54',
	authDomain : 'inspacco-prophandy.firebaseapp.com',
	databaseURL : 'https://inspacco-prophandy.firebaseio.com',
	projectId : 'inspacco-prophandy',
	storageBucket : '',
	messagingSenderId : '702808929642',
	appId : '1:702808929642:web:8896000502fbddfd'
};

if (process.env.REACT_APP_DEPLOYMENT_ENV === 'production') {
	console.log('Production config found');
	config = {
		apiKey : 'AIzaSyCGED4Qcat31J9fRkjEMVGfuliZH_ocg9U',
		authDomain : 'admin-console.inspacco.com',
		databaseURL : 'https://inspacco-prophandy-production.firebaseio.com',
		projectId : 'inspacco-prophandy-production',
		storageBucket : '',
		messagingSenderId : '659912076915',
		appId : '1:659912076915:web:794c1830a1f7d130'
	};
}

if (!firebase.apps.length) {
	firebase.initializeApp(config);
}

export default firebase;
