import React from 'react';
import Exception from '../../components/exceptions';

const Exception403 = () => (
	<Exception
		type="403"
		desc={"Sorry, you don't have access to this page"}
		backText="Go back"
	/>
);

export default Exception403;
