import React, { Component } from 'react';
import { Button, Form, Input, Select } from 'antd';
import changeCase from 'change-case';

const { Option } = Select;

const defaultFormItemLayout = {
	labelCol : {
		xs : { span : 24 }
	},
	wrapperCol : {
		xs : { span : 24 }
	}
};

class OrderCreatePropertyForm extends Component {
	renderAddressArea = () => {
		const { areas = [], onAddressChange } = this.props;

		return (
			<Select
				showSearch
				style={{ width : '100%' }}
				placeholder="Please select area"
				onChange={(e) => {
					onAddressChange({ area : e });
				}}
				filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
			>
				{areas.map(area => (
					<Option key={area}>{changeCase.titleCase(area)}</Option>
				))}
			</Select>
		);
	};

	render() {
		const {
			form,
			onSubmit,
			onAddressChange,
			selectedAddress = {},
			formItemLayout = defaultFormItemLayout,
			showSubmitButton = false,
			loading,
			cities = []
		} = this.props;
		const { getFieldDecorator } = form;

		return (
			<Form onSubmit={onSubmit}>
				<Form.Item {...formItemLayout} label="Name Address">
					{getFieldDecorator('address.name', {
						initialValue : selectedAddress.name,
						rules : [
							{
								required : true,
								message : 'Please input address name!'
							}
						]
					})(
						<Input
							id="address.name"
							name="address.name"
							placeholder="Home/Office"
							onChange={(e) => {
								onAddressChange({
									name : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Plot Number">
					{getFieldDecorator('address.plotNumber', {
						initialValue : selectedAddress.plotNumber,
						rules : []
					})(
						<Input
							id="address.plotNumber"
							name="address.plotNumber"
							placeholder="Plot 22"
							onChange={(e) => {
								onAddressChange({
									plotNumber : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Address Line1">
					{getFieldDecorator('address.line1', {
						initialValue : selectedAddress.line1,
						rules : [
							{
								required : true,
								message : 'Please input line1!'
							}
						]
					})(
						<Input
							id="address.line1"
							name="address.line1"
							placeholder="Address line 1"
							onChange={(e) => {
								onAddressChange({
									line1 : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Address Line2">
					{getFieldDecorator('address.line2', {
						initialValue : selectedAddress.line2,
						rules : []
					})(
						<Input
							id="address.line2"
							name="address.line2"
							placeholder="Address line 2"
							onChange={(e) => {
								onAddressChange({
									line2 : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="City">
					{getFieldDecorator('address.city', {
						initialValue : selectedAddress.city,
						rules : [
							{
								required : true,
								message : 'Please input city'
							}
						]
					})(
						<Select
							style={{ width : '100%' }}
							placeholder="Please select city"
							onChange={(e) => {
								onAddressChange({ city : e });
							}}
						>
							{cities.map(city => (
								<Option key={city}>{changeCase.titleCase(city)}</Option>
							))}
						</Select>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Area">
					{getFieldDecorator('address.area', {
						initialValue : selectedAddress.area,
						rules : [
							{
								required : true,
								message : 'Please select area!'
							}
						]
					})(this.renderAddressArea())}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Pincode">
					{getFieldDecorator('address.pincode', {
						initialValue : selectedAddress.pincode,
						rules : [
							{
								required : true,
								message : 'Please enter pincode!'
							}
						]
					})(
						<Input
							id="address.pincode"
							name="address.pincode"
							placeholder="441205"
							onChange={(e) => {
								onAddressChange({
									pincode : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>
				{showSubmitButton && (
					<Form.Item>
						<Button type="primary" htmlType="submit" block loading={loading}>
							{selectedAddress._id ? 'Save' : 'Create'}
						</Button>
					</Form.Item>
				)}
			</Form>
		);
	}
}

export default OrderCreatePropertyForm;
