import React, { Component } from 'react';
import Page404 from './404';
import Page500 from './500';
import Page403 from './403';

import connect from 'react-redux/es/connect/connect';

import { setError } from '../../actions/error';

class ErrorHandler extends Component {
	componentDidCatch(error, errorInfo) {
		/*eslint-disable no-shadow*/
		const { setError } = this.props;
		console.log('Caught in error handler ********* ', error, errorInfo);
		setError({ type : 'JS', error });
	}

	renderError = () => {
		const { error : prop_error } = this.props;
		const { error } = prop_error;

		if (error.type === 'HTTP') {
			const status =				error.error && error.error.response ? error.error.response.status : 500;
			if (status === 403 || status === 401) {
				return <Page403 />;
			}
			if (status >= 400 && status <= 499) {
				return <Page404 />;
			}
			return <Page500 />;
		}

		return <Page500 />;
	};

	render() {
		const { error, children } = this.props;
		if (error.has_error) {
			return this.renderError();
		}
		return children;
	}
}

export default connect(
	state => state,
	{ setError }
)(ErrorHandler);
