import * as ActionTypes from '../constants/actions';

const default_state = {
	has_error : false,
	error : undefined
};

export default function error(state = default_state, action) {
	switch (action.type) {
		case ActionTypes.ERROR.SET_ERROR: {
			return {
				...state,
				has_error : true,
				error : action.error
			};
		}
		case ActionTypes.ERROR.UNSET_ERROR: {
			return {
				...state,
				has_error : false,
				error : undefined
			};
		}
		default: {
			return state;
		}
	}
}
