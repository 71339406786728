import cookies from 'js-cookie';

const AUTH_COOKIE_NAME = 'x-auth-token-admin';

export function isAuthCookiePresent() {
	return (
		cookies.get(AUTH_COOKIE_NAME) && cookies.get(AUTH_COOKIE_NAME) !== null
	);
}

export function saveAuthCookie(cookieValue) {
	cookies.set(AUTH_COOKIE_NAME, cookieValue);
}

export function removeAuthCookie() {
	cookies.remove(AUTH_COOKIE_NAME);
}

export function getAuthCookie() {
	//'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY3RpdmUiOnRydWUsImlzQ3NBZG1pbiI6ZmFsc2UsIl9pZCI6IjVkMWY3MjJhMWFmYTZmMGFhNmZlNjMyNiIsInBob25lIjoiNzY3Njc1MjE3OCIsInJvbGUiOiJjcyIsImNoYW5uZWwiOiJhcHAiLCJhZGRyZXNzZXMiOltdLCJjcmVhdGVkQXQiOiIyMDE5LTA3LTA1VDE1OjUyOjEwLjQ1MFoiLCJ1cGRhdGVkQXQiOiIyMDE5LTA3LTA1VDE1OjUyOjEwLjQ1MFoiLCJfX3YiOjAsImlhdCI6MTU2MzQ0ODkzMiwiZXhwIjoxNTY1MTc2OTMyfQ.kx7Saj6fsV66pQoZ2l_fhoutut2QeL16KR1ekaBJ4Bo';
	return cookies.get(AUTH_COOKIE_NAME);
}

export function getAuthTokenKey() {
	return AUTH_COOKIE_NAME;
}
