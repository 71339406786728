import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Table, message, Button, Input, Col, Row } from 'antd';
import _ from 'lodash';

import LayoutWrapper from '../../components/utility/layoutWrapper';
import PageHeader from '../../components/utility/pageHeader';
import Box from '../../components/utility/box';
import * as CategoryHeaders from '../../utils/table-headers/category-list';
import CategoryAddEditModal from '../../components/inventory/category-add-edit-modal';
import CategoryListPageWrapper from './styles/categories-list-page.style';
import basicStyle from '../../settings/basicStyle';

//Actions
import * as InventoryActions from '../../actions/inventory';

//Apis
import * as InventoryApis from '../../apis/inventory';

const { rowStyle, colStyle, gutter } = basicStyle;

class CategoriesListPage extends Component {
	state = {
		showAddEditModal : false,
		updateInProgress : false,
		itemsLoading : false
	};

	componentDidMount() {
		this.fetchItems();
		this.fetchCategories();
	}

	fetchItems = () => {
		this.setState({ itemsLoading : true });
		const { dispatch } = this.props;
		dispatch(
			InventoryActions.fetchAllItems({ active : true, limit : 1000 }, () => {
				this.setState({ itemsLoading : false });
				console.log('All items fetched');
			})
		);
	};

	fetchCategories = () => {
		this.setState({ itemsLoading : true });
		const { dispatch } = this.props;
		dispatch(
			InventoryActions.fetchAllCategories({ limit : 1000 }, () => {
				this.setState({ itemsLoading : false });
				console.log('All categories fetched');
			})
		);
	};

	onCategoryEditClick = (category) => {
		const selectedCategory = _.cloneDeep(category);
		selectedCategory.items = category.items.map(item => item._id);

		this.setState({ selectedCategory, showAddEditModal : true });
	};

	onFieldValueChange = (obj) => {
		const { selectedCategory } = this.state;
		this.setState({
			selectedCategory : _.assign({}, selectedCategory, obj)
		});
	};

	onAddCategoryClick = () => {
		const selectedCategory = {
			active : true,
			items : []
		};
		this.setState({
			selectedCategory,
			showAddEditModal : true
		});
	};

	onSubmitCategory = async () => {
		const { selectedCategory } = this.state;
		console.log(selectedCategory);
		this.setState({ updateInProgress : true });

		try {
			if (selectedCategory._id) {
				await InventoryApis.updateCategory(selectedCategory);
			} else {
				await InventoryApis.createCategory(selectedCategory);
			}

			message.success('Category saved!');
			this.setState({ updateInProgress : false, showAddEditModal : false });
			this.fetchCategories();
		} catch (err) {
			console.log(err);
			this.setState({ updateInProgress : false });
		}
	};

	render() {
		const {
			showAddEditModal,
			selectedCategory,
			updateInProgress,
			itemsLoading,
			searchText
		} = this.state;
		const { inventory } = this.props;
		const { items } = inventory.allItems;

		const categories = inventory.allCategories.categories.filter(
			category => !searchText
				|| category.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
		);

		return (
			<LayoutWrapper>
				<CategoryListPageWrapper>
					<PageHeader>Manage Categories</PageHeader>
					<Box>
						<div className="search-box">
							<Row style={rowStyle} gutter={gutter} justify="start">
								<Col lg={10} xs={20} style={colStyle}>
									<Input
										placeholder="Search Item"
										type="text"
										onChange={(e) => {
											this.setState({ searchText : e.target.value });
										}}
									/>
								</Col>
								<Col lg={14} xs={4} style={colStyle}>
									<Button
										type="primary"
										shape="circle"
										icon="plus"
										onClick={this.onAddCategoryClick}
									/>
								</Col>
							</Row>
						</div>
						<Table
							scroll={{ x : 1200 }}
							columns={CategoryHeaders.getHeaders(this.onCategoryEditClick)}
							dataSource={categories}
							loading={itemsLoading}
						/>
					</Box>
					{showAddEditModal && (
						<CategoryAddEditModal
							selectedCategory={selectedCategory}
							items={items}
							onCancel={() => {
								this.setState({ showAddEditModal : false });
							}}
							onFieldValueChange={this.onFieldValueChange}
							onSubmit={this.onSubmitCategory}
							loading={updateInProgress}
						/>
					)}
				</CategoryListPageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(CategoriesListPage);
