import { apiClient } from '../utils/api-client';

export function fetchOrders(params) {
	return apiClient.get('/v2/customer-supports/orders', {
		params
	});
}

export function createOrder(order) {
	return apiClient.post('/v2/customer-supports/orders', order);
}

export function fetchOrderById(id, params) {
	return apiClient.get(`/v2/customer-supports/orders/${id}`, {
		params
	});
}

export function updateOrder(id, body) {
	return apiClient.post(`/v2/customer-supports/orders/${id}`, body);
}

export function assignInspector(orderId, inspectorId) {
	return apiClient.post(
		`/v2/customer-supports/orders/${orderId}/inspectors/${inspectorId}`
	);
}

export function updateInspection(orderId, inspectionId, body) {
	return apiClient.post(
		`/v2/customer-supports/orders/${orderId}/inspections/${inspectionId}`,
		body
	);
}

export function updateAttachmentCaption(
	orderId,
	inspectionId,
	blockId,
	roomId,
	categoryId,
	itemId,
	attachmentId,
	caption
) {
	return apiClient.post(
		`/v2/customer-supports/orders/${orderId}/inspections/${inspectionId}/blocks/${blockId}/rooms/${roomId}/categories/${categoryId}/items/${itemId}/attachments/${attachmentId}`,
		{
			caption
		}
	);
}

export function deleteItemAttachment(
	orderId,
	inspectionId,
	blockId,
	roomId,
	categoryId,
	itemId,
	attachmentId
) {
	return apiClient.delete(
		`/v2/customer-supports/orders/${orderId}/inspections/${inspectionId}/blocks/${blockId}/rooms/${roomId}/categories/${categoryId}/items/${itemId}/attachments/${attachmentId}`
	);
}

export function deleteRoomAttachment(
	orderId,
	inspectionId,
	blockId,
	roomId,
	attachmentId
) {
	return apiClient.delete(
		`/v2/customer-supports/orders/${orderId}/inspections/${inspectionId}/blocks/${blockId}/rooms/${roomId}/attachments/${attachmentId}`
	);
}

export function uploadReport(orderId, inspectionId, body) {
	return apiClient.post(
		`/v2/customer-supports/orders/${orderId}/inspections/${inspectionId}/reports`,
		body
	);
}

export function finishOrder(orderId) {
	return apiClient.post(`/v2/customer-supports/orders/${orderId}/finish`);
}

export function generateDocReport(orderId, inspectionId) {
	return apiClient.get(
		`/v2/customer-supports/orders/${orderId}/inspections/${inspectionId}/doc-report`,
		{
			responseType : 'blob'
		}
	);
}
