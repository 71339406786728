import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

//Utils
import * as AuthUtils from './utils/auth';

//Components
import BasePage from './pages/app/app';
import SigninPage from './pages/signin/signin';
import Page404 from './pages/exceptions/404';
// import HomeIndex from './pages/home';
import ItemsListPage from './pages/inventory/items-list-page';
import CategoriesListPage from './pages/inventory/categories-list-page';
import RoomsListPage from './pages/inventory/rooms-list-page';
import UsersListPage from './pages/user/users-list-page';
import OrdersListPage from './pages/order/order-list-page';
import OrderCreatePage from './pages/order/order-create-page';
import OrderDetailPage from './pages/order/order-detail-page';
import InspectionSummaryPage from './pages/order/inspection/inspection-summary-page';
import InspectionSnapshotPage from './pages/order/inspection/inspection-snapshot-page';
import InspectionRoomsPage from './pages/order/inspection/inspection-rooms-page';

const PrivateRoute = ({ component : Component, ...rest }) => (
	<Route
		{...rest}
		/*eslint-disable no-return-assign*/
		render={props => (AuthUtils.isAuthCookiePresent() ? (
			<Component {...props} />
		) : (
			(window.location.href = '/signin')
		))
		}
	/>
);

const routes = (
	<Switch>
		<Route exact path="/signin" component={SigninPage} />
		<BasePage>
			<Switch>
				{/*Unauthenticated routes*/}

				{/*/!*Authenticated routes*!/*/}
				<PrivateRoute exact path="/" component={OrdersListPage} />
				<PrivateRoute exact path="/home" component={OrdersListPage} />

				<PrivateRoute exact path="/inventory/items" component={ItemsListPage} />
				<PrivateRoute
					exact
					path="/inventory/categories"
					component={CategoriesListPage}
				/>
				<PrivateRoute exact path="/inventory/rooms" component={RoomsListPage} />
				<PrivateRoute exact path="/users" component={UsersListPage} />
				<PrivateRoute exact path="/orders" component={OrdersListPage} />
				<PrivateRoute exact path="/orders/new" component={OrderCreatePage} />
				<PrivateRoute
					exact
					path="/orders/:orderId"
					component={OrderDetailPage}
				/>
				<PrivateRoute
					exact
					path="/orders/:orderId/inspections/:inspectionId/summary"
					component={InspectionSummaryPage}
				/>
				<PrivateRoute
					exact
					path="/orders/:orderId/inspections/:inspectionId/snapshot"
					component={InspectionSnapshotPage}
				/>
				<PrivateRoute
					exact
					path="/orders/:orderId/inspections/:inspectionId/rooms"
					component={InspectionRoomsPage}
				/>
				<Route path="/*" component={Page404} />
			</Switch>
		</BasePage>
	</Switch>
);

export default function configureRoutes() {
	return <BrowserRouter>{routes}</BrowserRouter>;
}
