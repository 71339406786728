import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Row, Col, Form, Button, message } from 'antd';
import _ from 'lodash';

import LayoutWrapper from '../../components/utility/layoutWrapper';
import OrderCreatePageWrapper from './styles/order-create-page.style';
import basicStyle from '../../settings/basicStyle';
import PageHeader from '../../components/utility/pageHeader';
import OrderCreatePropertyForm from '../../components/order/order-create/order-create-property-form';
import OrderCreateAddressForm from '../../components/inspaccoUiElements/form/address-form';
import OrderCreateOwnerForm from '../../components/order/order-create/order-create-owner-form';
import OrderCreateOtherDetailForm from '../../components/order/order-create/order-create-other-details-form';

//Actions
import * as SettingsActions from '../../actions/setting';
import Box from '../../components/utility/box';

//Apis
import * as OrderApis from '../../apis/order';

const { rowStyle, colStyle, gutter } = basicStyle;

class OrderCreatePage extends Component {
	state = {
		orderInProgress : false,
		order : {
			property : {
				type : 'house',
				info : {},
				address : {}
			},
			owner : {}
		}
	};

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(SettingsActions.fetchEnums());
		dispatch(SettingsActions.fetchCities());
	}

	fetchCityAreas = (city) => {
		console.log('City changed ', city);
		const { dispatch } = this.props;
		dispatch(SettingsActions.fetchCityAreas(city));
	};

	onOtherDetailsChange = (obj) => {
		let { order } = _.cloneDeep(this.state);
		order = _.merge({}, order, obj);

		this.setState({ order });
	};

	onPropertyValueChange = (obj) => {
		const { order } = _.cloneDeep(this.state);
		order.property = _.merge({}, order.property, obj);

		this.setState({ order });
	};

	onPropertyInfoChange = (obj) => {
		const { order } = _.cloneDeep(this.state);
		order.property.info = _.merge({}, order.property.info, obj);

		this.setState({ order });
	};

	onAddressChange = (obj) => {
		const { order } = _.cloneDeep(this.state);
		order.property.address = _.merge({}, order.property.address, obj);
		this.setState({ order });
		if (obj.city) {
			this.fetchCityAreas(obj.city);
		}
	};

	onOwnerInfoChange = (obj) => {
		console.log(obj);
		const { order } = _.cloneDeep(this.state);
		order.owner = _.merge({}, order.owner, obj);
		this.setState({ order });
	};

	onSubmit = (e) => {
		const { form, history } = this.props;
		const { order } = this.state;
		console.log(order);
		if (e) {
			e.preventDefault();
		}

		form.validateFieldsAndScroll(async (err) => {
			if (err) {
				message.error('Please fill required details!');
				return;
			}

			this.setState({ orderInProgress : true });

			try {
				order.property.info.num_bedroom = parseInt(
					order.property.info.num_bedroom,
					10
				);
				order.property.info.num_bathroom = parseInt(
					order.property.info.num_bathroom,
					10
				);
				order.property.info.has_terrace =					order.property.info.has_terrace === 'true';
				order.orderAmount = parseFloat(order.orderAmount);

				const response = await OrderApis.createOrder(order);
				history.push(`/orders/${response.data.order._id}`);
				message.success('Order created successfully');
			} catch (apiError) {
				console.log(apiError);
				this.setState({ orderInProgress : false });
			}
		});
	};

	render() {
		const { form, setting } = this.props;
		const { orderInProgress } = this.state;
		const { cities, areas } = setting;

		return (
			<LayoutWrapper>
				<OrderCreatePageWrapper>
					<PageHeader>Create New Order</PageHeader>
					<Row style={rowStyle} gutter={gutter}>
						<Col lg={8} md={12} xs={24} style={colStyle}>
							<div>
								<Box title="Enter Property Details">
									<OrderCreatePropertyForm
										form={form}
										onSumit={this.onSubmit}
										onPropertyValueChange={this.onPropertyValueChange}
										onPropertyInfoChange={this.onPropertyInfoChange}
										propertySubtypes={setting.enums.PROPERTY_SUBTYPES || []}
									/>
								</Box>
							</div>
							<div style={{ marginTop : '1em' }}>
								<Box title="Enter Customer Details">
									<OrderCreateOwnerForm
										form={form}
										onSumit={this.onSubmit}
										onOwnerInfoChange={this.onOwnerInfoChange}
									/>
								</Box>
							</div>
						</Col>
						<Col lg={8} md={12} xs={24} style={colStyle}>
							<Box title="Enter Address">
								<OrderCreateAddressForm
									form={form}
									onSumit={this.onSubmit}
									onAddressChange={this.onAddressChange}
									cities={cities}
									areas={areas}
								/>
							</Box>
						</Col>
						<Col lg={8} md={12} xs={24} style={colStyle}>
							<div>
								<Box title="Enter Other Details">
									<OrderCreateOtherDetailForm
										form={form}
										onSumit={this.onSubmit}
										onOtherDetailsChange={this.onOtherDetailsChange}
										propertyBuyTypes={setting.enums.PROPERTY_BUY_TYPE || []}
										inspectionTypes={setting.enums.INSPECTION_TYPES || []}
									/>
								</Box>
							</div>
							<div style={{ marginTop : '1em' }}>
								<Button
									onClick={this.onSubmit}
									type="primary"
									block
									loading={orderInProgress}
								>
									Create Order
								</Button>
							</div>
						</Col>
					</Row>
				</OrderCreatePageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

const OrderCreateFormPage = Form.create()(OrderCreatePage);
export default connect(mapStateToProps)(OrderCreateFormPage);
