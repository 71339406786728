import * as ActionTypes from '../constants/actions';
import * as UserApis from '../apis/user';

function setAllUsers(allUsers) {
	return {
		type : ActionTypes.USER.SET_ALL_USERS,
		allUsers
	};
}

export function fetchUsers(params, callback) {
	return async (dispatch) => {
		const response = await UserApis.fetchUsers(params);
		dispatch(setAllUsers(response.data));
		if (callback) {
			return callback();
		}
	};
}
