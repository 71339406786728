import React, { Component } from 'react';
import { Collapse, message, Table, Tabs, Button } from 'antd';
import connect from 'react-redux/es/connect/connect';

import * as OrderActions from '../../../actions/order';
import * as InventoryUtils from '../../../utils/inventory';
import * as InspectionRoomItemsHeaders from '../../../utils/table-headers/inspection-room-items';
import * as OrderApis from '../../../apis/order';

import LayoutWrapper from '../../../components/utility/layoutWrapper';
import InspectionDetailPageWrapper from '../styles/inspection-detail-page.style';
import PageHeader from '../../../components/utility/pageHeader';
import InspectionStepsDropdown from '../../../components/order/inspection/inspection-steps-dropdown';
import Skeleton from 'antd/lib/skeleton';
import Box from '../../../components/utility/box';
import InspectionImageEditorDrawer from '../../../components/order/inspection/inspection-image-editor-drawer';
import InspectionImageModal from '../../../components/order/inspection/inspection-image-modal';

const { TabPane } = Tabs;
const { Panel } = Collapse;

class InspectionRoomsPage extends Component {
	state = {
		selectedTabKey : ''
	};

	componentDidMount() {
		this.fetchOrder((order) => {
			this.setState({
				selectedTabKey :
					order.inspections[0].details.blocks[0]
					&& order.inspections[0].details.blocks[0]._id
			});
		});
	}

	fetchOrder = (callback) => {
		const { match, dispatch } = this.props;
		dispatch(
			OrderActions.fetchOrderById(match.params.orderId, {}, (order) => {
				const inspection = order.inspections[0];
				this.setState({
					selectedInspection : inspection,
					selectedOrder : order
				});

				if (callback) {
					callback(order);
				}
			})
		);
	};

	onImageShowClick = (
		selectedItem,
		selectedCategory,
		selectedRoom,
		selectedBlock,
		imageType
	) => {
		this.setState({
			selectedItem,
			selectedCategory,
			selectedRoom,
			selectedBlock,
			showImageDrawer : true,
			imageType
		});
	};

	renderCategory = (category, room, block) => (
		<div className="category-container">
			<Table
				columns={InspectionRoomItemsHeaders.getInspectionRoomItemsHeaders(
					(item) => {
						this.onImageShowClick(item, category, room, block, "item");
					}
				)}
				dataSource={category.items}
				pagination={false}
				scroll={{ x : true }}
			/>
		</div>
	);

	renderOverallCategoryStatus = (category) => {
		const details = {
			inProgress : 0,
			notOk : 0,
			ok : 0,
			na : 0,
			total : 0
		};

		category.items.forEach((item) => {
			if (item.action === null) {
				details.inProgress += 1;
			} else if (item.action === 'OK') {
				details.ok += 1;
			} else if (item.action === 'NOT_OK') {
				details.notOk += 1;
			} else {
				details.na += 1;
			}

			details.total += 1;
		});

		return (
			<div className="category-overall-status">
				<span style={{ marginRight : '1em' }}>
					<span style={{ color : 'green', marginRight : '5px' }}> OK </span>
					{details.ok}
				</span>
				<span style={{ marginRight : '1em' }}>
					<span style={{ color : 'red', marginRight : '5px' }}> NOT OK </span>
					{details.notOk}
				</span>
				<span style={{ marginRight : '1em' }}>
					<span style={{ color : 'orange', marginRight : '5px' }}>
						{' '}
						IN PROGRESS{' '}
					</span>
					{details.inProgress}
				</span>
				<span style={{ marginRight : '1em' }}>
					<span style={{ color : 'gray', marginRight : '5px' }}> NA </span>
					{details.na}
				</span>
			</div>
		);
	};

	renderRoom = (room, block) => (
		<div className="room-container">
			<Collapse expandIconPosition="left" accordion>
				{room.categories.map(category => (
					<Panel
						header={`${category.name} (${category.items.length})`}
						key={`${room._id}-${block._id}-${category._id}`}
						extra={this.renderOverallCategoryStatus(category)}
					>
						{this.renderCategory(category, room, block)}
					</Panel>
				))}
			</Collapse>
		</div>
	);

	renderBlock = block => (
		<div className="block-container">
			<h2>{block.name}</h2>
			<Tabs>
				{block.rooms.map(room => (
					<TabPane tab={room.name} key={`${block._id}-${room._id}`}>
						<div>
							{room.attachments && room.attachments.length > 0 ?
							<p>
								Room images : <Button
													onClick={() => {
														this.onImageShowClick(undefined, undefined, room, block, "room");
													}}
												>
													{room.attachments.length} Images
												</Button>
							</p>:
							<p>
								There are no room images for this room
							</p>
						 }
						</div>
						{this.renderRoom(room, block)}
					</TabPane>
				))}
			</Tabs>
		</div>
	);

	renderTabs = () => {
		const { selectedInspection, selectedTabKey } = this.state;

		return (
			<Tabs
				type="card"
				onChange={(activeKey) => {
					this.setState({ selectedTabKey : activeKey });
				}}
			>
				{selectedInspection.details.blocks.map(block => (
					<TabPane
						tab={InventoryUtils.getDisplayBlockName(block.name)}
						key={block._id}
					>
						{selectedTabKey === block._id && this.renderBlock(block)}
					</TabPane>
				))}
			</Tabs>
		);
	};

	onDeleteImage = async (attachmentId) => {
		const {
			selectedOrder,
			selectedInspection,
			selectedBlock,
			selectedRoom,
			selectedCategory,
			selectedItem,
			imageType
		} = this.state;
		if(imageType === "room"){
			await OrderApis.deleteRoomAttachment(
				selectedOrder._id,
				selectedInspection._id,
				selectedBlock._id,
				selectedRoom._id,
				attachmentId
			);
		}else if(imageType === "item"){
			await OrderApis.deleteItemAttachment(
				selectedOrder._id,
				selectedInspection._id,
				selectedBlock._id,
				selectedRoom._id,
				selectedCategory._id,
				selectedItem._id,
				attachmentId
			);
		}
		this.fetchOrder();
		message.success('Image deleted successfully');
	};

	onUpdateImageCaption = async (attachmentId, caption) => {
		const {
			selectedOrder,
			selectedInspection,
			selectedBlock,
			selectedRoom,
			selectedCategory,
			selectedItem
		} = this.state;
		await OrderApis.updateAttachmentCaption(
			selectedOrder._id,
			selectedInspection._id,
			selectedBlock._id,
			selectedRoom._id,
			selectedCategory._id,
			selectedItem._id,
			attachmentId,
			caption
		);
		this.fetchOrder();
		message.success('Comment updated successfully');
	};

	onImageClick = (url) => {
		this.setState({ selectedImageUrl : url });
	};

	render() {
		const { match, history } = this.props;
		const {
			selectedImageUrl,
			selectedOrder,
			selectedItem,
			selectedBlock,
			selectedCategory,
			selectedRoom,
			showImageDrawer,
			imageType
		} = this.state;

		return (
			<LayoutWrapper>
				<InspectionDetailPageWrapper>
					<div className="blocks-outer-container">
						<PageHeader>Inspection Snapshot</PageHeader>
						<InspectionStepsDropdown
							currentStep="rooms"
							orderId={match.params.orderId}
							inspectionId={match.params.inspectionId}
							history={history}
							selectedOrder={selectedOrder}
						/>

						<Box>
							<Skeleton loading={!selectedOrder} active>
								{selectedOrder && this.renderTabs()}
							</Skeleton>
						</Box>
					</div>
					{showImageDrawer && (
						<InspectionImageEditorDrawer
							selectedOrder={selectedOrder}
							selectedItem={selectedItem}
							selectedBlock={selectedBlock}
							selectedCategory={selectedCategory}
							selectedRoom={selectedRoom}
							imageType={imageType}
							showCaption={imageType === "room" ? false : true}
							onCancel={() => {
								this.setState({ showImageDrawer : false });
							}}
							onDeleteImage={this.onDeleteImage}
							onUpdateImageCaption={this.onUpdateImageCaption}
							onImageClick={this.onImageClick}
						/>
					)}

					{selectedImageUrl && (
						<InspectionImageModal
							url={selectedImageUrl}
							onCancel={() => {
								this.setState({ selectedImageUrl : undefined });
							}}
						/>
					)}
				</InspectionDetailPageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(InspectionRoomsPage);
