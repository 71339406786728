import React from 'react';
import { Button } from 'antd';
import StatusTag from '../../components/inspaccoUiElements/status-tag';

export function getHeaders(onClick) {
	return [
		{
			title : 'Id',
			dataIndex : '_id',
			key : '_id'
		},
		{
			title : 'Name',
			dataIndex : 'name',
			key : 'name'
		},
		{
			title : 'Description',
			dataIndex : 'description',
			key : 'description'
		},
		{
			title : 'Categories',
			dataIndex : 'categories',
			key : 'categories',
			render : (text, record) => (
				<span>{`Total ${record.categories.length} categories`}</span>
			)
		},
		{
			title : 'Room Type',
			dataIndex : 'type',
			key : 'type'
		},
		{
			title : 'Active',
			dataIndex : 'active',
			key : 'active',
			render : (text, record) => (
				<StatusTag text={record.active ? 'active' : 'disabled'} />
			)
		},
		{
			title : 'Edit',
			dataIndex : 'active',
			key : 'edit',
			render : (text, record) => (
				<Button
					onClick={() => {
						onClick(record);
					}}
					type="default"
					block
				>
					Edit
				</Button>
			)
		}
	];
}
