import app from './app';
import themeSwitcher from './themeSwitcher';
import error from './error';
import setting from './setting';
import inventory from './inventory';
import user from './user';
import order from './order';

export default {
	app,
	themeSwitcher,
	error,
	setting,
	inventory,
	user,
	order
};
