import * as ActionTypes from '../constants/actions';

const defaultState = {
	allOrders : {
		orders : [],
		count : 0,
		limit : 10,
		page : 0
	},
	selectedOrder : undefined
};

export default function setting(state = defaultState, action) {
	switch (action.type) {
		case ActionTypes.ORDER.SET_ALL_ORDERS: {
			return {
				...state,
				allOrders : action.allOrders
			};
		}
		case ActionTypes.ORDER.SET_SELECTED_ORDER: {
			return {
				...state,
				selectedOrder : action.selectedOrder
			};
		}
		default: {
			return state;
		}
	}
}
