import React, { Component } from 'react';
import { Col, Row, Input, Button, Select } from 'antd';
import basicStyle from '../../settings/basicStyle';
import UserSearchWrapper from './styles/user-search.style';

const { rowStyle, colStyle, gutter } = basicStyle;
const { Option } = Select;

class UserSearch extends Component {
	render() {
		const {
			email,
			phone,
			name,
			role,
			allRoles,
			onSearchValueChange,
			onSearchSubmit,
			onUserAdd,
			hideRoles,
			hideUserAdd
		} = this.props;

		return (
			<UserSearchWrapper>
				<Row style={rowStyle} gutter={gutter} justify="start">
					<Col lg={5} xs={12} style={colStyle}>
						<p>Name</p>
						<Input
							name="name"
							id="name"
							value={name}
							onChange={(e) => {
								onSearchValueChange({ name : e.target.value });
							}}
						/>
					</Col>

					<Col lg={5} xs={12} style={colStyle}>
						<p>Email</p>
						<Input
							name="email"
							id="email"
							value={email}
							onChange={(e) => {
								onSearchValueChange({ email : e.target.value });
							}}
						/>
					</Col>

					<Col lg={5} xs={12} style={colStyle}>
						<p>Phone</p>
						<Input
							name="phone"
							id="phone"
							value={phone}
							onChange={(e) => {
								onSearchValueChange({ phone : e.target.value });
							}}
						/>
					</Col>

					{!hideRoles && (
						<Col lg={4} xs={12} style={colStyle}>
							<p>Role</p>
							<Select
								value={role}
								style={{ width : '100%' }}
								placeholder="All"
								onChange={(e) => {
									onSearchValueChange({ role : e });
								}}
							>
								<Option key="all" value="">
									ALL
								</Option>
								{allRoles.map(input => (
									<Option key={input} value={input}>
										{input.toUpperCase()}
									</Option>
								))}
							</Select>
						</Col>
					)}

					<Col lg={2} xs={6} style={colStyle} className="search-btn">
						<Button type="primary" onClick={onSearchSubmit}>
							Search
						</Button>
					</Col>

					{!hideUserAdd && (
						<Col lg={2} xs={6} style={colStyle} className="search-btn">
							<Button
								type="primary"
								shape="circle"
								icon="plus"
								onClick={onUserAdd}
							/>
						</Col>
					)}
				</Row>
			</UserSearchWrapper>
		);
	}
}

export default UserSearch;
