import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const OrderCreatePageWrapper = styled.div`
	width: 100%;
	.ant-form-item-label {
		text-align: left;
	}
`;

export default WithDirection(OrderCreatePageWrapper);
