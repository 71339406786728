import React, { Component } from 'react';
import { Col, Row, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import basicStyle from '../../settings/basicStyle';
import UserSearchWrapper from './styles/order-search.style';

const { rowStyle, colStyle, gutter } = basicStyle;

class OrderSearch extends Component {
	render() {
		const {
			ownerEmail,
			ownerPhone,
			orderId,
			onSearchValueChange,
			onSearchSubmit
		} = this.props;

		return (
			<UserSearchWrapper>
				<Row style={rowStyle} gutter={gutter} justify="start">
					<Col md={5} xs={12} style={colStyle}>
						<p>Order ID</p>
						<Input
							name="orderId"
							id="orderId"
							value={orderId}
							onChange={(e) => {
								onSearchValueChange({ orderId : e.target.value });
							}}
						/>
					</Col>

					<Col md={5} xs={12} style={colStyle}>
						<p>Owner Email</p>
						<Input
							name="ownerEmail"
							id="ownerEmail"
							placeholder="hello@gmail.com"
							value={ownerEmail}
							onChange={(e) => {
								onSearchValueChange({ ownerEmail : e.target.value });
							}}
						/>
					</Col>

					<Col md={5} xs={12} style={colStyle}>
						<p>Owner Phone</p>
						<Input
							name="Owner Phone"
							id="ownerPhone"
							type="number"
							value={ownerPhone}
							onChange={(e) => {
								onSearchValueChange({ ownerPhone : e.target.value });
							}}
						/>
					</Col>

					<Col md={2} xs={6} style={colStyle} className="search-btn">
						<Button type="primary" onClick={onSearchSubmit}>
							Search
						</Button>
					</Col>

					<Col md={2} xs={6} style={colStyle} className="search-btn">
						<Link to="/orders/new">
							<Button type="primary" shape="circle" icon="plus" />
						</Link>
					</Col>
				</Row>
			</UserSearchWrapper>
		);
	}
}

export default OrderSearch;
