import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`
	-webkit-overflow-scrolling: touch;
	.trigger {
		font-size: 18px;
		line-height: 64px;
		padding: 0 16px;
		cursor: pointer;
		transition: color 0.3s;
	}

	.ant-statistic {
		font-size: 14px;
		font-variant: tabular-nums;
		-webkit-font-feature-settings: 'tnum';
		font-feature-settings: 'tnum';
		line-height: 1.5;
		color: rgba(0, 0, 0, 0.65);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.ant-statistic-title {
		font-size: 14px;
		margin-bottom: 4px;
	}

	.ant-statistic-content {
		font-size: 24px;
	}

	.ant-statistic-content-suffix {
		margin-left: 4px;
		font-size: 16px;
		display: inline-block;
	}

	.trigger:hover {
		color: ${palette('primary', 0)};
	}

	.ant-tabs-nav .ant-tabs-tab-active {
		color: ${palette('primary', 0)} !important;
		font-weight: 500;
	}

	.ant-layout-sider-collapsed .anticon {
		font-size: 16px;
	}

	.ant-layout-sider-collapsed .nav-text {
		display: none;
	}

	.ant-layout {
		background: ${palette('secondary', 1)};

		&.isoContentMainLayout {
			overflow: auto;
			overflow-x: hidden;
			@media only screen and (min-width: 768px) and (max-width: 1220px) {
				width: calc(100% - 80px);
				flex-shrink: 0;
			}

			@media only screen and (max-width: 767px) {
				width: 100%;
				flex-shrink: 0;
			}
		}
	}

	.isoLayoutContent {
		width: 100%;
		padding: 35px;
		background-color: #ffffff;
		border: 1px solid ${palette('border', 0)};
		height: 100%;
	}

	.isomorphicLayout {
		width: calc(100% - 240px);
		flex-shrink: 0;
		overflow-x: hidden !important;

		@media only screen and (max-width: 767px) {
			width: 100%;
		}

		@media only screen and (min-width: 768px) and (max-width: 1220px) {
			width: calc(100% - 80px);
			width: 100%;
		}
	}

	.isoLayoutContentWrapper {
		@media only screen and (min-width: 1200px) {
			width: 1200px;
			margin: 0 auto;
		}

		@media only screen and (min-width: 900px) and (max-width: 1199px) {
			width: 1000px;
			margin: 0 auto;
		}

		@media only screen and (min-width: 500px) and (max-width: 899px) {
			width: 800px;
			margin: 0 auto;
		}

		@media only screen and (max-width: 500px) {
			width: 100%;
			margin: 0 auto;
		}
	}

	.ant-layout-footer {
		font-size: 13px;
		@media (max-width: 767px) {
			padding: 10px 20px;
		}
	}

	.primary-link {
		color: ${palette('primary', 0)} !important;
		cursor: pointer;
	}

	.primary-tag {
		color: white;
		background: ${palette('primary', 0)};
		border-radius: 1em;
		border-color: ${palette('primary', 0)};
	}

	.secondary-tag {
		color: white;
		background: ${palette('grayscale', 1)};
		border-radius: 1em;
		border-color: ${palette('grayscale', 1)};
	}

	hr {
		border-top: 0.07rem dashed;
		margin-top: 1em;
		margin-bottom: 1em;
		opacity: 0.2;
	}

	.btn {
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		user-select: none;
		border: 1px solid transparent;
	}

	.btn.btn-lg {
		padding: 1rem 1rem;
		font-size: 1rem;
		line-height: 1.25;
		border-radius: 0.3rem;
	}

	.btn.outline-success {
		background-color: transparent !important;
		background-image: none !important;
		border-color: ${palette('success', 0)} !important;
	}

	.btn.outline-success {
		color: ${palette('success', 0)} !important;
	}

	.btn.outline-success:hover {
		background-color: ${palette('success', 0)} !important;
		background-image: none !important;
		border-color: ${palette('success', 0)} !important;
	}

	.btn.outline-success:hover:not(:disabled) {
		color: #ffffff !important;
	}

	.btn.outline-danger {
		background-color: transparent !important;
		background-image: none !important;
		border-color: ${palette('error', 0)} !important;
		color: ${palette('error', 0)} !important;
	}

	.btn.outline-danger:hover {
		background-color: ${palette('error', 0)} !important;
		background-image: none !important;
		border-color: ${palette('error', 0)} !important;
		color: white !important;
	}

	.btn.outline-primary {
		background-color: transparent !important;
		background-image: none !important;
		border-color: ${palette('primary', 0)} !important;
		color: ${palette('primary', 0)} !important;
	}

	.btn.outline-primary:hover {
		background-color: ${palette('primary', 0)} !important;
		background-image: none !important;
		border-color: ${palette('primary', 0)} !important;
	}

	.btn.btn-primary-block {
		color: ${palette('primary', 0)} !important;
		background-color: ${palette('primary', 0)} !important;
		text-transform: uppercase;
		span {
			font-weight: bold;
			color: white;
		}
	}

	.btn.outline-white {
		background-color: transparent !important;
		background-image: none !important;
		border-color: white !important;
		color: white !important;
	}

	.btn.outline-white:hover {
		background-color: ${palette('primary', 0)} !important;
		background-image: none !important;
		border-color: ${palette('primary', 0)} !important;
	}

	.btn.outline-primary:hover:not(:disabled) {
		color: #ffffff !important;
	}

	.btn.outline-metal {
		background-color: transparent !important;
		background-image: none !important;
		border-color: ${palette('grayscale', 1)} !important;
		color: ${palette('grayscale', 1)} !important;
	}

	.btn.outline-metal:hover {
		background-color: ${palette('grayscale', 1)} !important;
		background-image: none !important;
		border-color: ${palette('grayscale', 1)} !important;
	}

	.btn.outline-metal:hover:not(:disabled) {
		color: #ffffff !important;
	}

	.btn:not(:disabled):not(.disabled) {
		cursor: pointer !important;
	}

	.btn-block {
		display: block;
		width: 100%;
	}

	.btn.disabled {
		pointer-events: none;
	}

	.text-align-right {
		text-align: right;
	}

	.text-align-left {
		text-align: left;
	}

	.text-align-center {
		text-align: center;
	}

	.text-success {
		color: ${palette('success', 0)};
	}

	.text-danger {
		color: ${palette('danger', 0)};
	}

	.portlet {
		-webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
		-moz-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
		box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
		background-color: #ffffff;
	}

	.portlet-round {
		border-radius: 5px;
	}

	.portlet-head {
		display: table;
		padding: 0;
		width: 100%;
		padding: 0 2.2rem;
		height: 5.1rem;
	}

	.portlet-body {
		color: #575962;
	}

	@media (min-width: 993px) {
		.portlet.portlet-full-height {
			height: calc(100% - 2.2rem);
		}

		.portlet.portlet-full-height .portlet-body {
			height: calc(100% - 2.2rem - 2.2rem);
		}
		.portlet.portlet-half-height {
			height: calc(50% - 2.2rem);
		}
	}

	.pull-right {
		float: right;
	}

	.pull-left {
		float: left;
	}

	.margin-left-10 {
		margin-left: 10px;
	}

	.margin-right-10 {
		margin-right: 10px;
	}

	.m--font-success {
		color: ${palette('success', 0)};
	}

	.m--font-warning {
		color: ${palette('warning', 0)};
	}

	.event-registered-checkmark {
		font-weight: 600;
		font-size: 16px;
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: ${palette('primary', 0)} !important;
		border-color: ${palette('primary', 0)} !important;
	}

	.ant-menu-horizontal > .ant-menu-item > a:hover {
		color: ${palette('primary', 0)};
	}

	.ant-menu-horizontal > .ant-menu-item:hover,
	.ant-menu-horizontal > .ant-menu-submenu:hover,
	.ant-menu-horizontal > .ant-menu-item-active,
	.ant-menu-horizontal > .ant-menu-submenu-active,
	.ant-menu-horizontal > .ant-menu-item-open,
	.ant-menu-horizontal > .ant-menu-submenu-open,
	.ant-menu-horizontal > .ant-menu-item-selected,
	.ant-menu-horizontal > .ant-menu-submenu-selected {
		border-bottom: 2px solid ${palette('primary', 0)};
		color: ${palette('primary', 0)};
	}

	.ant-menu-horizontal > .ant-menu-item-selected > a {
		color: ${palette('primary', 0)};
	}

	.ant-btn-primary {
		color: #fff;
		background-color: ${palette('primary', 0)} !important;
		border-color: ${palette('primary', 0)} !important;
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
		box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
	}

	.ant-btn[disabled] {
		color: #d8d8d8 !important;
		background-color: #f7f7f7 !important;
		border-color: #e9e9e9 !important;
		cursor: not-allowed !important;
	}

	.ant-card {
		border-radius: 5px;
		height: 100%;
		cursor: pointer;
		font-size: 14px;
		-webkit-font-feature-settings: 'tnum';
		font-feature-settings: 'tnum';
		font-variant: tabular-nums;
		line-height: 1.5;
		color: rgba(0, 0, 0, 0.65);
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		list-style: none;
		/* background: #fff; */
		border-radius: 2px;
		position: relative;
		-webkit-transition: all 0.3s;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
		border-radius: 5px;

		.featured-event-image {
			height: 150px;
			width: 300px;
			object-fit: cover;
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
		}
	}

	.no-border {
		background-color: transparent !important;
		box-shadow: none;
		border: 0px;
	}

	.featured-row .ant-card-body {
		padding: 0px !important;
		zoom: 1 !important;
	}

	.card-ancillary-info {
		font-weight: 400;
		font-size: 0.75rem;
		color: #333439;
	}

	.card-image {
		width: 100%;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
	}

	@media (max-width: 575px) {
		.featured-first-card {
			margin-left: 15px;
		}
		.featured-row {
			padding-right: 15px;
			margin-right: -35px !important;
			margin-left: -35px !important;
		}
		.horizontal-container {
			max-width: 400px !important;
			padding-right: 0px !important;
			padding-left: 0px !important;
			overflow: hidden;
		}
	}
	@media (min-width: 576px) and (max-width: 767px) {
		.featured-first-card {
			margin-left: 15px;
		}
		.featured-row {
			padding-right: 15px;
			margin-right: -35px !important;
			margin-left: -35px !important;
		}
		.horizontal-container {
			max-width: 576px !important;
			padding-right: 0px !important;
			padding-left: 0px !important;
			overflow: hidden;
		}
	}
	@media (min-width: 768px) and (max-width: 991px) {
		.featured-row {
			padding-left: 5px;
		}
		.featured-first-card {
			margin-left: 15px;
		}
		.featured-row {
			padding-right: 15px;
			margin-right: -35px !important;
			margin-left: -35px !important;
		}
		.horizontal-container {
			max-width: 768px !important;
			padding-right: 0px !important;
			padding-left: 0px !important;
			overflow: hidden;
		}
	}
	@media (min-width: 992px) and (max-width: 1199px) {
		.fc-center {
			padding-right: 200px;
		}
		.featured-first-card {
			margin-left: 25px;
		}
		.featured-row {
			padding-right: 15px;
			margin-right: -45px !important;
			margin-left: -45px !important;
		}
		.horizontal-container {
			max-width: 992px !important;
			padding-right: 0px !important;
			padding-left: 0px !important;
			overflow: hidden;
		}
	}
	@media (min-width: 1200px) {
		.fc-center {
			padding-right: 200px;
		}
		.featured-first-card {
			margin-left: -5px;
		}
		.featured-row {
			padding-right: 15px;
			margin-right: -20px !important;
		}
		.horizontal-container {
			max-width: 1200px !important;
			padding-right: 0px !important;
			padding-left: 0px !important;
			overflow: hidden;
		}
	}

	/* Featured cards */

	.horizontal-row {
		overflow-x: auto !important;
		white-space: nowrap !important;
		margin-bottom: -20px !important;
		padding-bottom: 20px !important;
	}
	.horizontal-row > .ant-col-lg-8 {
		display: inline-block !important;
		float: none !important;
	}

	.horizontal-container-cell {
		width: 320px !important;
		max-width: 320px !important;
		padding-right: 0px !important;
		padding-left: 10px !important;
	}
	.horizontal-container-cell-featured-cards {
		width: 300px !important;
		max-width: 300px !important;
		padding-right: 0px !important;
		margin-right: 20px;
	}
	.horizontal-container-cell-featured-cards .m-portlet {
		background-color: transparent !important;
		box-shadow: none !important;
	}
	.featured-cards {
		height: 150px !important;
		width: 300px !important;
		background-size: cover;
		background-position: center !important;
		border-radius: 5px 5px 0px 0px !important;
		padding: 0px;
	}
	/* Event cards */
	.horizontal-container-cell-events {
		width: 300px !important;
		max-width: 300px !important;
		padding-right: 0px !important;
		padding-left: 5px !important;
		margin-right: 10px;
	}

	p {
		margin: 10px 0px !important;
	}

	.image-overlay {
		background: linear-gradient(
				to bottom,
				transparent 0%,
				rgba(0, 0, 0, 0.6) 0%,
				rgba(0, 0, 0, 0.6) 90%
			)
			no-repeat scroll 0 0;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.group-cover-photo {
		height: 375px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	a {
		color: ${palette('primary', 0)};
		background-color: transparent;
		text-decoration: none;
		outline: none;
		cursor: pointer;
		-webkit-transition: color 0.3s;
		transition: color 0.3s;
		-webkit-text-decoration-skip: objects;
	}

	.ant-tabs-ink-bar {
		z-index: 1;
		position: absolute;
		left: 0;
		bottom: 1px;
		box-sizing: border-box;
		height: 2px;
		background-color: ${palette('primary', 0)};
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	.event-going-check {
		margin: 1em;
		color: ${palette('success', 0)};
	}

	.flex-justify-align-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flex-align-center {
		display: flex;
		align-items: center;
	}
	.flex-justify-center-align-baseline {
		display: flex;
		align-items: baseline;
		justify-content: center;
	}
	.font-size-1-2em {
		font-size: 1.2em;
	}

	.ant-radio-group {
		.ant-radio-checked {
			.ant-radio-inner {
				border-color: ${palette('primary', 0)};
				&::after {
					background-color: ${palette('primary', 0)};
				}
			}
		}
	}

	.tiles-heading-column {
		margin-bottom: 10px;
		h2 {
			font-weight: 500;
		}
	}

	.ant-select {
		width: 100%;
	}

	${'' /* button {
    border-radius: 0;
  } */};
`;

export default AppHolder;
