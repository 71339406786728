import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, LocaleProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import moment from 'moment';

//Actions
import appActions from '../../actions/app';
import * as SettingActions from '../../actions/setting';

import Topbar from '../../components/topbar/topbar';
import Sidebar from '../../components/sidebar/sidebar';

import { AppLocale } from '../../baseApp';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import * as AuthUtils from '../../utils/auth';
import './global.css';

//For quill rich text editor
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
const { fetchProfile } = SettingActions;

export class App extends Component {
	componentWillMount() {
		const { history } = this.props;
		if (!AuthUtils.isAuthCookiePresent()) {
			history.push('/signin');
		}
	}

	componentDidMount() {
		/*eslint-disable no-shadow*/
		const { fetchProfile } = this.props;

		if (AuthUtils.isAuthCookiePresent()) {
			fetchProfile();
		}
	}

	componentDidUpdate() {}

	render() {
		const { history } = this.props;
		const is_authenticated = AuthUtils.isAuthCookiePresent();

		if (!is_authenticated) {
			history.push('/signin');
			return null;
		}

		const {
			locale = 'en',
			selectedTheme = 'themedefault',
			setting,
			toggleAll,
			children,
			match
		} = this.props;
		const { user } = setting;
		if (is_authenticated && !user) {
			console.log('Profile fetch in progress');
			return null;
		}

		const currentAppLocale = AppLocale[locale];
		const appHeight = window.innerHeight;

		//Override primary colors.
		const theme = themes[selectedTheme];

		const { url } = match;

		return (
			<LocaleProvider locale={currentAppLocale.antd}>
				<IntlProvider
					locale={currentAppLocale.locale}
					messages={currentAppLocale.messages}
				>
					<ThemeProvider theme={theme}>
						<AppHolder>
							<Layout style={{ height : appHeight }}>
								<Debounce time="1000" handler="onResize">
									<WindowResizeListener
										onResize={windowSize => toggleAll(windowSize.windowWidth, windowSize.windowHeight)
										}
									/>
								</Debounce>
								<Topbar {...this.props} />
								<Layout style={{ flexDirection : 'row', overflowX : 'hidden' }}>
									<Sidebar url={url} />
									<Layout className="isoContentMainLayout">
										<Content
											className="isomorphicContent"
											style={{
												padding : '70px 0 0',
												flexShrink : '0',
												background : '#f1f3f6',
												position : 'relative'
											}}
										>
											{children}
										</Content>
										<Footer
											style={{
												background : '#ffffff',
												textAlign : 'center'
											}}
										>
											{' by PropHandy Technologies, Pvt Ltd. ©'}
											{moment().year()}
										</Footer>
									</Layout>
								</Layout>
							</Layout>
						</AppHolder>
					</ThemeProvider>
				</IntlProvider>
			</LocaleProvider>
		);
	}
}

export default withRouter(
	connect(
		state => state,
		{ toggleAll, fetchProfile }
	)(App)
);
