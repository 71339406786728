import * as ActionTypes from '../constants/actions';
import * as OrderApis from '../apis/order';
import _ from 'lodash';

const defaultOrderListPopulate = 'owner,inspector';
const defaultSingleOrderPopulate =	'owner,inspector,broker,inspections,property,leadGenerator';

function setAllOrders(allOrders) {
	return {
		type : ActionTypes.ORDER.SET_ALL_ORDERS,
		allOrders
	};
}

export function fetchOrders(input_params, callback) {
	return async (dispatch) => {
		const params = _.cloneDeep(input_params);
		params.populate = defaultOrderListPopulate;
		const response = await OrderApis.fetchOrders(params);
		dispatch(setAllOrders(response.data));
		if (callback) {
			return callback();
		}
	};
}

export function setSelectedOrder(selectedOrder) {
	return {
		type : ActionTypes.ORDER.SET_SELECTED_ORDER,
		selectedOrder
	};
}

export function fetchOrderById(id, input_params = {}, callback) {
	return async (dispatch) => {
		const params = _.cloneDeep(input_params);
		if (!params.populate) {
			params.populate = defaultSingleOrderPopulate;
		}

		const response = await OrderApis.fetchOrderById(id, params);
		dispatch(setSelectedOrder(response.data.order));
		if (callback) {
			return callback(response.data.order);
		}
	};
}
