import React, { Component } from 'react';
import { Form, Input, Select } from 'antd';

const { Option } = Select;

const formItemLayout = {
	labelCol : {
		xs : { span : 24 }
	},
	wrapperCol : {
		xs : { span : 24 }
	}
};

class OrderCreatePropertyForm extends Component {
	renderPropertySubType = () => {
		const { propertySubtypes, onPropertyValueChange } = this.props;

		return (
			<Select
				style={{ width : '100%' }}
				placeholder="Please select"
				onChange={(e) => {
					onPropertyValueChange({ subType : e });
				}}
			>
				{propertySubtypes.map(subtype => (
					<Option key={subtype}>{subtype.toUpperCase()}</Option>
				))}
			</Select>
		);
	};

	renderPropertyBuyType = () => {
		const { propertyBuyTypes, onOtherDetailsChange } = this.props;

		return (
			<Select
				style={{ width : '100%' }}
				placeholder="Please select"
				onChange={(e) => {
					onOtherDetailsChange({ propertyBuyType : e });
				}}
			>
				{propertyBuyTypes.map(subtype => (
					<Option key={subtype}>{subtype.toUpperCase()}</Option>
				))}
			</Select>
		);
	};

	render() {
		const { form, onSubmit, onPropertyInfoChange } = this.props;
		const { getFieldDecorator } = form;

		return (
			<Form onSubmit={onSubmit}>

				<Form.Item {...formItemLayout} label="Property Sub Type">
					{getFieldDecorator('subType', {
						rules : [
							{
								required : true,
								message : 'Please input Property Type!'
							}
						]
					})(this.renderPropertySubType())}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Total Bedrooms">
					{getFieldDecorator('info.num_bedroom', {
						rules : [
							{
								required : true,
								message : 'Please input number of bedrooms!'
							}
						]
					})(
						<Input
							id="info.num_bedroom"
							name="info.num_bedroom"
							placeholder="2"
							onChange={(e) => {
								onPropertyInfoChange({
									num_bedroom : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Total Bathrooms">
					{getFieldDecorator('info.num_bathroom', {
						rules : [
							{
								required : true,
								message : 'Please input number of bathrooms!'
							}
						]
					})(
						<Input
							id="info.num_bathroom"
							name="info.num_bathroom"
							placeholder="2"
							onChange={(e) => {
								onPropertyInfoChange({
									num_bathroom : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>
			</Form>
		);
	}
}

export default OrderCreatePropertyForm;
