import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import appActions from '../../actions/app';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';

const { Header } = Layout;

class Topbar extends Component {
	render() {
		const { toggleCollapsed, customizedTheme, locale, openDrawer } = this.props;
		let { collapsed } = this.props;

		collapsed = collapsed && !openDrawer;
		const styling = {
			background : customizedTheme.backgroundColor,
			position : 'fixed',
			width : '100%',
			height : 70
		};
		return (
			<TopbarWrapper>
				<Header
					style={styling}
					className={
						collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
					}
				>
					<div className="isoLeft">
						<button
							type="button"
							className={
								collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
							}
							style={{ color : customizedTheme.textColor }}
							onClick={toggleCollapsed}
						/>
					</div>

					<ul className="isoRight">
						<li className="isoUser">
							<TopbarUser locale={locale} />
						</li>
					</ul>
				</Header>
			</TopbarWrapper>
		);
	}
}

export default connect(
	state => ({
		...state.app,
		locale : 'en',
		customizedTheme : state.themeSwitcher.topbarTheme,
		setting : state.setting
	}),
	{ toggleCollapsed : appActions.toggleCollapsed }
)(Topbar);
