import { apiClient } from '../utils/api-client';

//Items
export function fetchItems(params) {
	return apiClient.get('/v2/customer-supports/items', {
		params
	});
}

export function createItem(item) {
	return apiClient.post('/v2/customer-supports/items', item);
}

export function updateItem(item) {
	return apiClient.post(`/v2/customer-supports/items/${item._id}`, item);
}

//Categories
export function fetchCategories(params) {
	return apiClient.get('/v2/customer-supports/categories', {
		params
	});
}

export function createCategory(category) {
	return apiClient.post('/v2/customer-supports/categories', category);
}

export function updateCategory(category) {
	return apiClient.post(
		`/v2/customer-supports/categories/${category._id}`,
		category
	);
}

//Rooms
export function fetchRooms(params) {
	return apiClient.get('/v2/customer-supports/rooms', {
		params
	});
}

export function createRoom(room) {
	return apiClient.post('/v2/customer-supports/rooms', room);
}

export function updateRoom(room) {
	return apiClient.post(`/v2/customer-supports/rooms/${room._id}`, room);
}
