import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const OrderDetailWrapper = styled.div`
	width: 100%;
	.title {
		span {
			font-weight: bold;
		}
	}
`;

export default WithDirection(OrderDetailWrapper);
