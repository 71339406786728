import React from 'react';
import Exception from '../../components/exceptions';

const Exception404 = () => (
	<Exception
		type="404"
		desc="Sorry, the page you visited does not exist"
		// linkElement={Link}
		backText="Go back"
	/>
);

export default Exception404;
