import React from 'react';
import { Button } from 'antd';
import StatusTag from '../../components/inspaccoUiElements/status-tag';

export function getDefaultHeaders(onClick) {
	return [
		{
			title : 'Id',
			dataIndex : '_id',
			key : '_id',
			render : (text, record) => (
				<span>
					{text}
					{record.code && <h4>(Code : {record.code})</h4>}
				</span>
			)
		},
		{
			title : 'Name',
			dataIndex : 'name',
			key : 'name'
		},
		{
			title : 'Email',
			dataIndex : 'email',
			key : 'email'
		},
		{
			title : 'Phone',
			dataIndex : 'phone',
			key : 'phone'
		},
		{
			title : 'Role',
			dataIndex : 'role',
			key : 'role'
		},
		{
			title : 'Active',
			dataIndex : 'active',
			key : 'active',
			render : (text, record) => (
				<StatusTag text={record.active ? 'active' : 'disabled'} />
			)
		},
		{
			title : 'Edit',
			dataIndex : 'active',
			key : 'edit',
			render : (text, record) => (
				<Button
					onClick={() => {
						onClick(record);
					}}
					type="default"
					block
				>
					Edit
				</Button>
			)
		}
	];
}

export function getBrokerSelectHeaders(onClick) {
	return [
		{
			title : 'Id',
			dataIndex : '_id',
			key : '_id',
			render : (text, record) => (
				<span>
					{text}
					{record.code && <h4>(Code : {record.code})</h4>}
				</span>
			)
		},
		{
			title : 'Name',
			dataIndex : 'name',
			key : 'name'
		},
		{
			title : 'Email',
			dataIndex : 'email',
			key : 'email'
		},
		{
			title : 'Phone',
			dataIndex : 'phone',
			key : 'phone'
		},
		{
			title : 'Active',
			dataIndex : 'active',
			key : 'active',
			render : (text, record) => (
				<StatusTag text={record.active ? 'active' : 'disabled'} />
			)
		},
		{
			title : 'Select',
			dataIndex : 'active',
			key : 'select',
			render : (text, record) => (
				<Button
					onClick={() => {
						onClick(record);
					}}
					type="default"
					block
				>
					Select
				</Button>
			)
		}
	];
}
