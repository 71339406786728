import { apiClient } from '../utils/api-client';

export function fetchUsers(params) {
	return apiClient.get('/v2/customer-supports/users', {
		params
	});
}

export function updateUser(user) {
	return apiClient.post(`/v2/customer-supports/users/${user._id}`, user);
}

export function createUser(user) {
	return apiClient.post('/v2/customer-supports/users', user);
}

export function uploadProfileLogo(formData) {
	return apiClient.post('/v2/customer-supports/upload-single-file', formData);
}
