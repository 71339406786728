import React from 'react';
import { Modal } from 'antd';

export default (props) => {
	const { url, onCancel } = props;
	return (
		<Modal
			footer={null}
			onCancel={onCancel}
			width={1200}
			visible={true}
			style={{ top : 10 }}
		>
			<img src={url} style={{ width : '100%' }} alt="inspection" />
		</Modal>
	);
};
