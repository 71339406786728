import * as ActionTypes from '../constants/actions';

const defaultState = {
	allItems : {
		items : [],
		limit : 100,
		page : 0
	},
	allCategories : {
		categories : [],
		limit : 100,
		page : 0
	},
	allRooms : {
		rooms : [],
		limit : 100,
		page : 0
	}
};

export default function setting(state = defaultState, action) {
	switch (action.type) {
		case ActionTypes.INVENTORY.SET_ALL_ITEMS: {
			return {
				...state,
				allItems : action.allItems
			};
		}
		case ActionTypes.INVENTORY.SET_ALL_CATEGORIES: {
			return {
				...state,
				allCategories : action.allCategories
			};
		}
		case ActionTypes.INVENTORY.SET_ALL_ROOMS: {
			return {
				...state,
				allRooms : action.allRooms
			};
		}
		default: {
			return state;
		}
	}
}
