import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import SignInStyleWrapper from './styles/signin.style';

import firebase from '../../utils/firebase';

import * as AuthUtils from '../../utils/auth';
import * as SettingsActions from '../../actions/setting';

const { login } = SettingsActions;

class SignIn extends Component {
	constructor(props) {
		super(props);
		this.uiConfig = {
			signInFlow : 'popup',
			signInOptions : [
				{
					provider : firebase.auth.GoogleAuthProvider.PROVIDER_ID
				}
				// firebase.auth.FacebookAuthProvider.PROVIDER_ID
			],
			callbacks : {
				// Avoid redirects after sign-in.
				signInSuccessWithAuthResult : (authResult) => {
					this.handleLogin(authResult);
					return false;
				}
			}
		};
	}

	componentDidMount() {
		const { history } = this.props;
		if (AuthUtils.isAuthCookiePresent()) {
			console.log('Cookie present');
			history.push('/');
		}
	}

	handleLogin = (authResult) => {
		console.log(authResult);
		const { history } = this.props;

		login(authResult.user, () => {
			history.push('/');
		});

		console.log('Handling login');
	};

	render() {
		return (
			<SignInStyleWrapper className="isoSignInPage">
				<div className="isoLoginContentWrapper">
					<div className="isoLoginContent">
						<div className="isoLogoWrapper">
							<Link to="/">CS Login</Link>
						</div>

						<StyledFirebaseAuth
							uiConfig={this.uiConfig}
							firebaseAuth={firebase.auth()}
						/>
					</div>
				</div>
			</SignInStyleWrapper>
		);
	}
}

export default connect(
	state => state,
	{ login }
)(SignIn);
