import React from 'react';
import Exception from '../../components/exceptions';

const Exception500 = () => (
	<Exception
		type="500"
		desc="Sorry, the server is reporting an error"
		// linkElement={Link}
		backText="Go back"
	/>
);

export default Exception500;
