import React from 'react';
import { Provider } from 'react-redux';
import { store, history } from './store';
import PublicRoutes from './router';
import { ThemeProvider } from 'styled-components';
import { LocaleProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import themes from './settings/themes';
import AppLocale from './languageProvider';

import { themeConfig } from './settings';
import BaseAppHolder from './baseAppStyle';
import ErrorHandler from './pages/exceptions/errorHandler';

const currentAppLocale = AppLocale.en;

const BaseApp = () => (
	<LocaleProvider locale={currentAppLocale.antd}>
		<IntlProvider
			locale={currentAppLocale.locale}
			messages={currentAppLocale.messages}
		>
			<ThemeProvider theme={themes[themeConfig.theme]}>
				<BaseAppHolder>
					<Provider store={store}>
						<ErrorHandler>
							<PublicRoutes history={history} />
						</ErrorHandler>
					</Provider>
				</BaseAppHolder>
			</ThemeProvider>
		</IntlProvider>
	</LocaleProvider>
);

export default BaseApp;
export { AppLocale };
