const options = [
	{
		key : 'home',
		label : 'Dashboard',
		leftIcon : 'ion-android-home'
	},
	{
		key : 'orders',
		label : 'Orders',
		leftIcon : 'ion-ios-cart'
	},
	{
		key : 'users',
		label : 'Users',
		leftIcon : 'ion-android-people'
	},
	{
		key : 'property',
		label : 'Manage Inventory',
		leftIcon : 'ion-android-create',
		children : [
			{
				key : 'inventory/items',
				label : 'Items'
			},
			{
				key : 'inventory/categories',
				label : 'Categories'
			},
			{
				key : 'inventory/rooms',
				label : 'Rooms'
			}
		]
	}
];
export default options;
