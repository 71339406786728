import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Table, message, Button } from 'antd';
import _ from 'lodash';

import LayoutWrapper from '../../components/utility/layoutWrapper';
import PageHeader from '../../components/utility/pageHeader';
import Box from '../../components/utility/box';
import * as RoomHeaders from '../../utils/table-headers/room-list';
import RoomAddEditModal from '../../components/inventory/room-add-edit-modal';
import RoomListPageWrapper from './styles/room-list-page.style';

//Actions
import * as InventoryActions from '../../actions/inventory';
import * as SettingActions from '../../actions/setting';

//Apis
import * as InventoryApis from '../../apis/inventory';

class RoomsListPage extends Component {
	state = {
		showAddEditModal : false,
		updateInProgress : false,
		itemsLoading : false
	};

	componentDidMount() {
		this.fetchRooms();
		this.fetchCategoriesAndEnum();
	}

	fetchRooms = () => {
		this.setState({ itemsLoading : true });
		const { dispatch } = this.props;
		dispatch(
			InventoryActions.fetchAllRooms({ limit : 100 }, () => {
				this.setState({ itemsLoading : false });
				console.log('All items fetched');
			})
		);
	};

	fetchCategoriesAndEnum = () => {
		const { dispatch } = this.props;
		dispatch(
			InventoryActions.fetchAllCategories({ active : true, limit : 1000 })
		);

		dispatch(SettingActions.fetchEnums());
	};

	onRoomEditClick = (room) => {
		const selectedRoom = _.cloneDeep(room);
		selectedRoom.categories = room.categories.map(item => item._id);

		this.setState({ selectedRoom, showAddEditModal : true });
	};

	onFieldValueChange = (obj) => {
		console.log(obj);
		const { selectedRoom } = this.state;
		this.setState({
			selectedRoom : _.assign({}, selectedRoom, obj)
		});
	};

	onAddRoomClick = () => {
		const selectedRoom = {
			active : true,
			categories : []
		};
		this.setState({ selectedRoom, showAddEditModal : true });
	};

	onSubmitRoom = async () => {
		const { selectedRoom } = this.state;
		console.log(selectedRoom);
		this.setState({ updateInProgress : true });

		try {
			if (selectedRoom._id) {
				await InventoryApis.updateRoom(selectedRoom);
			} else {
				await InventoryApis.createRoom(selectedRoom);
			}

			message.success('Room saved!');
			this.setState({ updateInProgress : false, showAddEditModal : false });
			this.fetchRooms();
		} catch (err) {
			console.log(err);
			this.setState({ updateInProgress : false });
		}
	};

	render() {
		const {
			showAddEditModal,
			selectedRoom,
			updateInProgress,
			itemsLoading
		} = this.state;
		const { inventory, setting } = this.props;
		const { rooms } = inventory.allRooms;
		const { categories } = inventory.allCategories;
		const { enums } = setting;

		return (
			<LayoutWrapper>
				<RoomListPageWrapper>
					<PageHeader>Manage Rooms</PageHeader>
					<Box>
						<div className="search-box">
							<Button
								type="primary"
								shape="circle"
								icon="plus"
								onClick={this.onAddRoomClick}
							/>
						</div>
						<Table
							scroll={{ x : 1200 }}
							columns={RoomHeaders.getHeaders(this.onRoomEditClick)}
							dataSource={rooms}
							loading={itemsLoading}
						/>
					</Box>
					{showAddEditModal && (
						<RoomAddEditModal
							selectedRoom={selectedRoom}
							categories={categories}
							onCancel={() => {
								this.setState({ showAddEditModal : false });
							}}
							onFieldValueChange={this.onFieldValueChange}
							onSubmit={this.onSubmitRoom}
							loading={updateInProgress}
							roomTypes={enums.ROOM_TYPES || []}
						/>
					)}
				</RoomListPageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(RoomsListPage);
