import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popover from '../uielements/popover';
import { message, Avatar } from 'antd';
import * as SettingActions from '../../actions/setting';
import TopbarDropdownWrapper from './topbarDropdown.style';

const { logout } = SettingActions;

class TopbarUser extends Component {
	constructor(props) {
		super(props);
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
		this.hide = this.hide.bind(this);
		this.state = {
			visible : false
		};
	}

	hide() {
		this.setState({ visible : false });
	}

	handleVisibleChange() {
		const { visible } = this.state;
		this.setState({ visible : !visible });
	}

	doLogOut = () => {
		message.loading('Logging you out...', 0);
		logout();
		window.location.href = '/signin';
	};

	render() {
		const { visible } = this.state;
		const { setting } = this.props;

		const content = (
			<TopbarDropdownWrapper className="isoUserDropdown">
				<Link className="isoDropdownLink" to="/settings">
					Settings
				</Link>
				<a className="isoDropdownLink" onClick={this.doLogOut} href="# ">
					Logout
				</a>
			</TopbarDropdownWrapper>
		);

		return (
			<Popover
				content={content}
				trigger="click"
				visible={visible}
				onVisibleChange={this.handleVisibleChange}
				arrowPointAtCenter={true}
				placement="bottomLeft"
			>
				<Avatar size="large" icon="user" src={setting.user.profileUrl} />
			</Popover>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(TopbarUser);
