import React, { Component } from 'react';
import { Modal, Table } from 'antd';
import connect from 'react-redux/es/connect/connect';
import Box from '../utility/box';
import UserSearchBox from './user-search';
import _ from 'lodash';
import * as UserActions from '../../actions/user';
import * as UserHeaders from '../../utils/table-headers/user-list';

class BrokerListModal extends Component {
	state = {
		search : {
			role : 'broker'
		}
	};

	componentDidMount() {
		this.fetchBrokers(0);
	}

	onSearchValueChange = (obj) => {
		const { search } = this.state;
		this.setState({ search : _.merge({}, search, obj) });
	};

	onSearchSubmit = () => {
		this.fetchBrokers(0);
	};

	fetchBrokers = (page) => {
		this.setState({ usersLoading : true });
		const { dispatch } = this.props;
		const { search } = this.state;

		dispatch(
			UserActions.fetchUsers({ page, limit : 10, ...search }, () => {
				this.setState({ usersLoading : false });
				console.log('All users fetched');
			})
		);
	};

	render() {
		const { onBrokerSelect, onCancel, user } = this.props;
		const { users, count, limit, page } = user.allUsers;
		const { search, usersLoading } = this.state;

		return (
			<Modal
				footer={null}
				onCancel={onCancel}
				width={1200}
				visible={true}
				style={{ top : 10 }}
			>
				<div>
					<div style={{ marginBottom : '1em' }}>
						<Box title="Search">
							<UserSearchBox
								{...search}
								onSearchValueChange={this.onSearchValueChange}
								onSearchSubmit={this.onSearchSubmit}
								hideRoles={true}
								hideUserAdd={true}
							/>
						</Box>
					</div>
					<div>
						<Box>
							<Table
								columns={UserHeaders.getBrokerSelectHeaders(onBrokerSelect)}
								dataSource={users}
								loading={usersLoading}
								pagination={{
									current : page + 1,
									pageSize : limit,
									total : count
								}}
								onChange={(currentPagination) => {
									this.fetchUsers(currentPagination.current - 1);
								}}
							/>
						</Box>
					</div>
				</div>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(BrokerListModal);
