import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const OrderListPageWrapper = styled.div`
	width: 100%;
	.search-box {
		margin-bottom: 1em;
	}
`;

export default WithDirection(OrderListPageWrapper);
