export const SETTING = {
	SET_PROFILE : 'SET_PROFILE',
	TOGGLE_AUTH_IN_PROGRESS : 'TOGGLE_AUTH_IN_PROGRESS',
	SET_ENUMS : 'SET_ENUMS',
	SET_CITIES : 'SET_CITIES',
	SET_AREAS : 'SET_AREAS'
};

export const INVENTORY = {
	SET_ALL_ITEMS : 'SET_ALL_ITEMS',
	SET_ALL_CATEGORIES : 'SET_ALL_CATEGORIES',
	SET_ALL_ROOMS : 'SET_ALL_ROOMS'
};

export const ERROR = {
	SET_ERROR : 'SET_ERROR',
	UNSET_ERROR : 'UNSET_ERROR'
};

export const USER = {
	SET_ALL_USERS : 'SET_ALL_USERS'
};

export const ORDER = {
	SET_ALL_ORDERS : 'SET_ALL_ORDERS',
	SET_SELECTED_ORDER : 'SET_SELECTED_ORDER'
};
