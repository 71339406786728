import React from 'react';
import { Button } from 'antd';
import StatusTag from '../../components/inspaccoUiElements/status-tag';

export function getHeaders(onClick) {
	return [
		{
			title : 'Id',
			dataIndex : '_id',
			key : '_id'
		},
		{
			title : 'Name',
			dataIndex : 'name',
			key : 'name'
		},
		{
			title : 'Description',
			dataIndex : 'description',
			key : 'description'
		},
		{
			title : 'Count Required',
			dataIndex : 'countRequired',
			key : 'countRequired',
			render : (text, record) => (
				<span>{record.countRequired ? 'Required' : 'Not Required'}</span>
			)
		},
		{
			title : 'Brand Required',
			dataIndex : 'brandRequired',
			key : 'brandRequired',
			render : (text, record) => (
				<span>{record.brandRequired ? 'Required' : 'Not Required'}</span>
			)
		},
		{
			title : 'Is Optional',
			dataIndex : 'isOptional',
			key : 'isOptional',
			render : (text, record) => (
				<span>{record.isOptional ? 'Optional' : 'Required'}</span>
			)
		},
		{
			title : 'Active',
			dataIndex : 'active',
			key : 'active',
			render : (text, record) => (
				<StatusTag text={record.active ? 'active' : 'disabled'} />
			)
		},
		{
			title : 'Edit',
			dataIndex : 'active',
			key : 'createdAt',
			render : (text, record) => (
				<Button
					onClick={() => {
						onClick(record);
					}}
					type="default"
					block
				>
					Edit
				</Button>
			)
		}
	];
}
