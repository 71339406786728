import React from 'react';
import { Link } from 'react-router-dom';
import StatusTag from '../../components/inspaccoUiElements/status-tag';
import moment from 'moment';

export function getHeaders() {
	return [
		{
			title : 'Id',
			dataIndex : '_id',
			key : '_id',
			render : text => <Link to={`/orders/${text}`}>{text}</Link>
		},
		{
			title : 'Owner Name',
			dataIndex : 'owner.name',
			key : 'owner.name',
			render : (text, record) => (
				<span>{record.ownerName || record.owner.name}</span>
			)
		},
		{
			title : 'Owner Email',
			dataIndex : 'owner.email',
			key : 'owner.email'
		},
		{
			title : 'Owner Phone',
			dataIndex : 'owner.phone',
			key : 'owner.phone'
		},
		{
			title : 'Inspector Name',
			dataIndex : 'inspector.name',
			key : 'inspector.name'
		},
		{
			title : 'Status',
			dataIndex : 'status',
			key : 'status',
			render : text => <StatusTag text={text} />
		},
		{
			title : 'CreatedOn',
			dataIndex : 'createdAt',
			key : 'createdAt',
			render : text => <span>{moment(text).format('lll')}</span>
		}
	];
}
