import * as ActionTypes from '../constants/actions';

const defaultState = {
	isAuthInProgress : false,
	user : undefined,
	enums : {},
	cities : [],
	areas : []
};

/**
 * This is only for logged in user. All other user managements are under Accounts.
 */
export default function setting(state = defaultState, action) {
	switch (action.type) {
		case ActionTypes.SETTING.SET_PROFILE: {
			return {
				...state,
				user : action.user
			};
		}
		case ActionTypes.SETTING.SET_ENUMS: {
			return {
				...state,
				enums : action.enums
			};
		}
		case ActionTypes.SETTING.SET_CITIES: {
			return {
				...state,
				cities : action.cities
			};
		}
		case ActionTypes.SETTING.SET_AREAS: {
			return {
				...state,
				areas : action.areas
			};
		}
		default: {
			return state;
		}
	}
}
