import { apiClient } from '../utils/api-client';

export function login() {}

export function fetchProfile() {
	return apiClient.get('/v2/customer-supports/profile');
}

export function fetchEnums() {
	return apiClient.get('/v2/customer-supports/enums');
}

export function fetchCities() {
	return apiClient.get('/v2/public/cities');
}

export function fetchCityAreas(city) {
	return apiClient.get(`/v2/public/cities/${city}/areas`);
}
