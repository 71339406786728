import React from 'react';
import { ComponentTitleWrapper } from './pageHeader.style';

export default (props) => {
	const { children } = props;
	return (
		<ComponentTitleWrapper className="isoComponentTitle">
			{children}
		</ComponentTitleWrapper>
	);
};
