import React from 'react';
import { BoxTitle, BoxSubTitle } from './boxTitle.style';

export default (props) => {
	const { title, subtitle } = props;
	return (
		<div>
			{title ? <BoxTitle className="isoBoxTitle"> {title} </BoxTitle> : ''}
			{subtitle ? (
				<BoxSubTitle className="isoBoxSubTitle"> {subtitle} </BoxSubTitle>
			) : (
				''
			)}
		</div>
	);
};
