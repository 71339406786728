import * as ActionTypes from '../constants/actions';
import * as InventoryApis from '../apis/inventory';

function setAllItems(allItems) {
	return {
		type : ActionTypes.INVENTORY.SET_ALL_ITEMS,
		allItems
	};
}

export function fetchAllItems(params, callback) {
	return async (dispatch) => {
		const response = await InventoryApis.fetchItems(params);
		dispatch(setAllItems(response.data));
		if (callback) {
			return callback(response.data);
		}
	};
}

function setAllCategories(allCategories) {
	return {
		type : ActionTypes.INVENTORY.SET_ALL_CATEGORIES,
		allCategories
	};
}

export function fetchAllCategories(params, callback) {
	return async (dispatch) => {
		const response = await InventoryApis.fetchCategories(params);
		dispatch(setAllCategories(response.data));
		if (callback) {
			return callback(response.data);
		}
	};
}

function setAllRooms(allRooms) {
	return {
		type : ActionTypes.INVENTORY.SET_ALL_ROOMS,
		allRooms
	};
}

export function fetchAllRooms(params, callback) {
	return async (dispatch) => {
		const response = await InventoryApis.fetchRooms(params);
		dispatch(setAllRooms(response.data));
		if (callback) {
			return callback(response.data);
		}
	};
}
