import React, { Component } from 'react';
import { Modal } from 'antd';

import AddressForm from './form/address-form';

class AddressEditCreateModal extends Component {
	render() {
		const { selectedAddress = {}, onCancel } = this.props;
		return (
			<Modal
				title={selectedAddress._id ? 'Edit Address' : 'Add Address'}
				onCancel={onCancel}
				footer={null}
				visible={true}
			>
				<AddressForm {...this.props} />
			</Modal>
		);
	}
}

export default AddressEditCreateModal;
