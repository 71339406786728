import React, { Component } from 'react';
import { Form, Input } from 'antd';

const formItemLayout = {
	labelCol : {
		xs : { span : 24 }
	},
	wrapperCol : {
		xs : { span : 24 }
	}
};

class OrderCreateOwnerForm extends Component {
	render() {
		const { form, onSubmit, onOwnerInfoChange } = this.props;
		const { getFieldDecorator } = form;

		return (
			<Form onSubmit={onSubmit}>
				<Form.Item {...formItemLayout} label="Name">
					{getFieldDecorator('owner.name', {
						rules : [
							{
								required : true,
								message : 'Please input customer name!'
							}
						]
					})(
						<Input
							id="owner.name"
							name="owner.name"
							placeholder="Nitin Gupta"
							onChange={(e) => {
								onOwnerInfoChange({
									name : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Phone">
					{getFieldDecorator('owner.phone', {
						rules : [
							{
								required : true,
								message : 'Please input customer phone!'
							}
						]
					})(
						<Input
							id="owner.phone"
							name="owner.phone"
							placeholder="10 digit phone number"
							onChange={(e) => {
								onOwnerInfoChange({
									phone : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>

				<Form.Item {...formItemLayout} label="Email">
					{getFieldDecorator('owner.email', {
						rules : []
					})(
						<Input
							id="owner.email"
							name="owner.email"
							placeholder="customer-email@gmail.com"
							onChange={(e) => {
								onOwnerInfoChange({
									email : e.target.value
								});
							}}
						/>
					)}
				</Form.Item>
			</Form>
		);
	}
}

export default OrderCreateOwnerForm;
