import React, { Component } from 'react';
import { Card, Icon, Popconfirm, Input } from 'antd';
import _ from 'lodash';

const { Meta } = Card;
const { TextArea } = Input;

class InspectionImageCard extends Component {
	state = {
		isEdit : false
	};

	componentDidMount() {
		const { attachment } = this.props;
		this.setState({ attachment });
	}

	render() {
		const { onDeleteImage, onUpdateImageCaption, onImageClick, showCaption } = this.props;
		const { attachment, isEdit } = _.cloneDeep(this.state);

		if (!attachment) {
			return null;
		}
		let actions = [
			<Popconfirm
						title="Delete Image, sure？"
						okText="Yes"
						cancelText="No"
						onConfirm={() => {
							onDeleteImage(attachment._id);
						}}
					>
						<Icon type="delete" />
					</Popconfirm>,

					<a href={attachment.url} target="_blank" rel="noopener noreferrer">
						<Icon type="download" />
					</a>
		]
		if(showCaption){
			actions.unshift(<Icon
				type="edit"
				onClick={() => {
					this.setState({ isEdit : true });
				}}
			/>);
		}

		return (
			<Card
				key={attachment._id}
				hoverable
				cover={(
					<img
						alt="example"
						src={attachment.url}
						onClick={() => {
							onImageClick(attachment.url);
						}}
					/>
				)}
				actions={actions}
			>
				{!isEdit && showCaption && <Meta title="Comment" description={attachment.caption} />}

				{isEdit && showCaption && (
					<TextArea
						value={attachment.caption}
						onChange={(e) => {
							attachment.caption = e.target.value;
							this.setState({ attachment });
						}}
						onBlur={() => {
							onUpdateImageCaption(attachment._id, attachment.caption);
							this.setState({ isEdit : false });
						}}
					/>
				)}
			</Card>
		);
	}
}

export default InspectionImageCard;
