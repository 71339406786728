import * as AuthUtils from '../utils/auth';
import * as ActionTypes from '../constants/actions';
import * as SettingsApis from '../apis/setting';
import * as AuthApis from '../apis/auth';

export async function login(user, callback) {
	const response = await AuthApis.login(user.uid, user.displayName);
	AuthUtils.saveAuthCookie(response.data.token);
	if (callback) {
		return callback(response.data.user);
	}
}

export function logout() {
	AuthUtils.removeAuthCookie();
}

function setProfile(user) {
	return {
		type : ActionTypes.SETTING.SET_PROFILE,
		user
	};
}

export function fetchProfile(callback) {
	return async (dispatch) => {
		const response = await SettingsApis.fetchProfile();
		dispatch(setProfile(response.data.user));
		if (callback) {
			return callback(response.data.user);
		}
	};
}

function setEnums(enums) {
	return {
		type : ActionTypes.SETTING.SET_ENUMS,
		enums
	};
}

export function fetchEnums(callback) {
	return async (dispatch) => {
		const response = await SettingsApis.fetchEnums();
		dispatch(setEnums(response.data.enums));
		if (callback) {
			return callback(response.data.enums);
		}
	};
}

export function setCities(cities) {
	return {
		type : ActionTypes.SETTING.SET_CITIES,
		cities
	};
}

export function setAreas(areas) {
	return {
		type : ActionTypes.SETTING.SET_AREAS,
		areas
	};
}

export function fetchCities(callback) {
	return async (dispatch) => {
		const response = await SettingsApis.fetchCities();
		dispatch(setCities(response.data.cities));
		if (callback) {
			return callback(response.data.cities);
		}
	};
}

export function fetchCityAreas(city, callback) {
	return async (dispatch) => {
		const response = await SettingsApis.fetchCityAreas(city);
		dispatch(setAreas(response.data.areas));
		if (callback) {
			return callback(response.data.areas);
		}
	};
}
