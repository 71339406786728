import * as InventoryUtils from '../inventory';

export function getAllCountHeaders() {
	return [
		{
			title : 'Item',
			dataIndex : 'name'
		},
		{
			title : 'Count',
			dataIndex : 'count'
		}
	];
}

export function getWorkingNonWorkingHeaders(headers) {
	return [
		{
			title : '',
			dataIndex : 'title'
		},
		...headers.map(header => ({
			title : InventoryUtils.getDisplayBlockName(header),
			dataIndex : header,
			render : (text, record) => {
				if (record.title === 'Rating') {
					return `${parseFloat(text).toFixed(2)}%`;
				}

				return text;
			}
		}))
	];
}
