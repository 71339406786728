import React from 'react';
import BoxTitleWrapper from './boxTitle';
import { BoxWrapper } from './box.style';

export default (props) => {
	const { className, title, style, subtitle, children } = props;

	return (
		<BoxWrapper className={`${className} isoBoxWrapper`} style={style}>
			<BoxTitleWrapper title={title} subtitle={subtitle} />
			{children}
		</BoxWrapper>
	);
};
