import axios from 'axios';
import { notification } from 'antd';
import _ from 'lodash';

import * as AuthUtils from './auth';
import { store } from '../store';
import * as ErrorActions from '../actions/error';

function isLoginCall(config) {
	return config.url.indexOf('/signin') !== -1;
}

// This client is actually the proxy client
const apiClient = axios.create({
	baseURL :
		process.env.REACT_APP_API_SERVICE_BASE_URL || 'http://localhost:40010',
	timeout : 60000 * 5, //5 min
	headers : {
		'Content-Type' : 'application/json'
	}
});

const requestMiddleware = (config) => {
	const token = AuthUtils.getAuthCookie();

	if (isLoginCall(config)) {
		return config;
	}

	const currentPage = window.location.pathname;
	let userId = '';

	const store_state = store.getState();
	if (store_state && store_state.setting && store_state.setting.user) {
		userId = store_state.setting.user._id || '';
		console.log(userId);
	}

	//To avoid modifying input params.
	const clonedConfig = _.cloneDeep(config);

	if (token) {
		clonedConfig.headers['x-auth-token'] = token;
	}

	clonedConfig.headers[
		'x-trace-id'
	] = `admin-console=${userId}=${currentPage}=${new Date().valueOf()}`;

	return clonedConfig;
};

const errorHandler = (error) => {
	//On safe side unset this.

	//Check this before.
	if (
		error.response
		&& error.response.status === 401
		&& !isLoginCall(error.response.config)
	) {
		AuthUtils.removeAuthCookie();
		window.location.href = '/signin';
		return;
	}

	// we only send to error page if error is not user error
	if (
		error.response
		&& (error.response.status !== 400 && error.response.status !== 403)
	) {
		store.dispatch(ErrorActions.setError({ type : 'HTTP', error }));
	}

	let message = '';
	if (error.response) {
		message = error.response.statusText;
	} else {
		message = error.error || error.message;
	}
	if (
		error.response
		&& (error.response.data.message || error.response.data.error)
	) {
		message = error.response.data.message || error.response.data.error;
	}

	if (message && message.detail) {
		message = message.detail;
	}

	notification.error({
		message : "Oops..something didn't go right",
		description : message
	});

	return Promise.reject(message);
};

apiClient.interceptors.request.use(requestMiddleware, error => Promise.reject(error));

// Add a response interceptor
apiClient.interceptors.response.use(
	response => response.data, //Data has data field inside.
	errorHandler
);

export { apiClient };
