import React, { Component } from 'react';
import {
	Form,
	Modal,
	Input,
	Switch,
	Button,
	Select,
	Icon,
	Row,
	Col
} from 'antd';
import _ from 'lodash';

const { Option } = Select;

const formItemLayout = {
	labelAlign : 'left',
	labelCol : {
		xs : { span : 24 },
		sm : { span : 8 }
	},
	wrapperCol : {
		xs : { span : 24 },
		sm : { span : 16 }
	}
};

const colStyle = {
	padding : '0px 2px'
};

const removeColStyle = {
	padding : '9px 10px'
};

const mappedFormItemLayout = {
	layout : 'vertical'
};

class ItemAddEditModal extends Component {
	componentWillMount() {
		this.setState({
			mappedNotOKReasons :
				(this.props.selectedItem
					&& this.props.selectedItem.mappedNotOKReasons)
				|| []
		});
	}

	componentWillUnmount() {
		this.setState({
			mappedNotOKReasons : undefined
		});
	}

	onSubmit = (e) => {
		const { form, onSubmit } = this.props;
		if (e) {
			e.preventDefault();
		}

		form.validateFieldsAndScroll((err) => {
			if (!err) {
				onSubmit();
			}
		});
	};

	onFieldValueChange = (obj, index) => {
		const mappedNotOKReasons = [...this.state.mappedNotOKReasons];

		mappedNotOKReasons[index][obj.key] = obj.value;
		const { onFieldValueChange } = this.props;
		this.setState(
			{
				mappedNotOKReasons
			},
			() => {
				onFieldValueChange(this.state);
			}
		);
	};

	onAddReason = () => {
		const mappedNotOKReasons = [...this.state.mappedNotOKReasons];

		mappedNotOKReasons.push({
			reason : undefined,
			subReason : undefined,
			serviceProvider : undefined,
			implication : undefined
		});

		this.setState({
			mappedNotOKReasons
		});
	};

	onRemoveReason = (index) => {
		const { onFieldValueChange } = this.props;

		const mappedNotOKReasons = [...this.state.mappedNotOKReasons];
		mappedNotOKReasons.splice(index, 1);

		this.setState(
			{
				mappedNotOKReasons
			},
			() => {
				onFieldValueChange(this.state);
			}
		);
	};

	renderMappedReasonSelect = (type, index) => {
		const { enums } = this.props;
		const { onFieldValueChange } = this;
		let enumType; let
			placeholder;
		switch (type) {
			default:
			case 'reason':
				enumType = 'INSPECTION_ITEM_NOT_OK_REASONS';
				placeholder = 'Reason';
				break;
			case 'subReason':
				enumType = 'INSPECTION_ITEM_NOT_OK_SUB_REASONS';
				placeholder = 'Sub Reason';
				break;
			case 'serviceProvider':
				enumType = 'SERVICE_PROVIDER_TYPES';
				placeholder = 'Service Provider';
				break;
			case 'implication':
				enumType = 'IMPLICATION';
				placeholder = 'Implication';
				break;
		}

		return (
			<Select
				style={{ width : '100%' }}
				placeholder={placeholder}
				dropdownMatchSelectWidth={false}
				onChange={(e) => {
					onFieldValueChange({ key : type, value : e }, index);
				}}
			>
				{enums[enumType].map(item => (
					<Option key={item}>{item}</Option>
				))}
			</Select>
		);
	};

	renderMappedReasons = (mappedReason, index) => {
		const { form } = this.props;

		const { getFieldDecorator } = form;
		const key =			`${index
			 }-${
			 mappedReason.reason
			 }-${
			 mappedReason.subReason
			 }-${
			 mappedReason.serviceProvider
			 }-${
			 mappedReason.implication}`;

		return (
			<Row key={key}>
				<Col lg={6} xs={12} style={colStyle} key={`${key}1`}>
					<Form.Item {...mappedFormItemLayout}>
						{getFieldDecorator(`reason[${key}]`, {
							initialValue : mappedReason.reason,
							rules : [
								{
									required : true,
									message : 'Please input Reason!'
								}
							]
						})(this.renderMappedReasonSelect('reason', index))}
					</Form.Item>
				</Col>

				<Col lg={5} xs={12} style={colStyle} key={`${key}2`}>
					<Form.Item {...mappedFormItemLayout}>
						{getFieldDecorator(`subReason[${key}]`, {
							initialValue : mappedReason.subReason,
							rules : [
								{
									required : true,
									message : 'Please input Sub Reasons!'
								}
							]
						})(this.renderMappedReasonSelect('subReason', index))}
					</Form.Item>
				</Col>

				<Col lg={6} xs={10} style={colStyle} key={`${key}3`}>
					<Form.Item {...mappedFormItemLayout}>
						{getFieldDecorator(`serviceProvider[${key}]`, {
							initialValue : mappedReason.serviceProvider,
							rules : [
								{
									required : true,
									message : 'Please input Service Provider!'
								}
							]
						})(this.renderMappedReasonSelect('serviceProvider', index))}
					</Form.Item>
				</Col>

				<Col lg={6} xs={10} style={colStyle} key={`${key}4`}>
					<Form.Item {...mappedFormItemLayout}>
						{getFieldDecorator(`implication[${key}]`, {
							initialValue : mappedReason.implication,
							rules : [
								{
									required : true,
									message : 'Please input Implication!'
								}
							]
						})(this.renderMappedReasonSelect('implication', index))}
					</Form.Item>
				</Col>
				<Col lg={1} xs={4} style={removeColStyle} key={`${key}5`}>
					<Icon
						className="dynamic-delete-button"
						type="minus-circle-o"
						onClick={() => this.onRemoveReason(index)}
					/>
				</Col>
			</Row>
		);
	};

	// renderDefaultReason = (type) => {
	// 	const { selectedItem, onFieldValueChange } = this.props;
	// 	let enumType;
	// 	switch (type) {
	// 		default:
	// 		case 'okDefaultReason':
	// 			enumType = 'possibleOkReasons';
	// 			break;
	// 		case 'notOkDefaultReason':
	// 			enumType = 'possibleNotOkReasons';
	// 			break;
	// 		case 'notOkDefaultSubReason':
	// 			enumType = 'possibleNotOkSubReasons';
	// 			break;
	// 	}
	// 	const changedValue = [];

	// 	return (
	// 		<Select
	// 			style={{ width : '100%' }}
	// 			placeholder="Please select"
	// 			onChange={(e) => {
	// 				changedValue[type] = e;
	// 				onFieldValueChange(changedValue);
	// 			}}
	// 		>
	// 			{selectedItem[enumType].map(item => (
	// 				<Option key={item}>{item}</Option>
	// 			))}
	// 		</Select>
	// 	);
	// };

	renderReasons = (type) => {
		const { enums, onFieldValueChange } = this.props;
		let enumType;
		switch (type) {
			default:
			case 'possibleOkReasons':
				enumType = 'INSPECTION_ITEM_OK_REASONS';
				break;
			case 'possibleNotOkReasons':
				enumType = 'INSPECTION_ITEM_NOT_OK_REASONS';
				break;
			case 'possibleNotOkSubReasons':
				enumType = 'INSPECTION_ITEM_NOT_OK_SUB_REASONS';
				break;
		}
		const changedValue = [];
		return (
			<Select
				mode="multiple"
				style={{ width : '100%' }}
				placeholder="Please select"
				onChange={(e) => {
					changedValue[type] = e;
					onFieldValueChange(changedValue);
				}}
				filterOption={(input, option) => !input
					|| option.props.children.toLowerCase().indexOf(input.toLowerCase())
						!== -1
				}
			>
				{enums[enumType].map(item => (
					<Option key={item}>{item}</Option>
				))}
			</Select>
		);
	};

	render() {
		const {
			form,
			selectedItem,
			onCancel,
			onFieldValueChange,
			loading
		} = this.props;

		const { mappedNotOKReasons } = this.state;

		let possibleNotReason = _.map(
			_.filter(mappedNotOKReasons, mappedReason => mappedReason.reason),
			filteredArray => filteredArray.reason
		);

		if (!possibleNotReason) {
			possibleNotReason = [];
		}

		const { getFieldDecorator } = form;

		return (
			<Modal
				title={selectedItem._id ? 'Edit Item' : 'Add New Item'}
				visible={true}
				width="920px"
				onCancel={onCancel}
				footer={null}
			>
				<Form onSubmit={this.onSubmit}>
					<Form.Item {...formItemLayout} label="Name">
						{getFieldDecorator('name', {
							initialValue : selectedItem.name,
							rules : [
								{
									required : true,
									message : 'Please input name!'
								}
							]
						})(
							<Input
								id="name"
								name="name"
								placeholder="Bulk Socket"
								onChange={(e) => {
									onFieldValueChange({
										name : e.target.value
									});
								}}
							/>
						)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Description">
						{getFieldDecorator('description', {
							initialValue : selectedItem.description,
							rules : [
								{
									required : true,
									message : 'Please input description!'
								}
							]
						})(
							<Input
								id="description"
								name="description"
								placeholder="Electrical holder on wall"
								onChange={(e) => {
									onFieldValueChange({
										description : e.target.value
									});
								}}
							/>
						)}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Capture Count?">
						{getFieldDecorator('countRequired', {
							initialValue : selectedItem.countRequired,
							rules : [
								{
									required : true,
									message : 'Please select countRequired!'
								}
							]
						})(
							<Switch
								checkedChildren="Yes"
								unCheckedChildren="No"
								checked={selectedItem.countRequired}
								onChange={(checked) => {
									onFieldValueChange({
										countRequired : checked
									});
								}}
							/>
						)}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Capture Brand?">
						{getFieldDecorator('brandRequired', {
							initialValue : selectedItem.brandRequired,
							rules : [
								{
									required : true,
									message : 'Please select brandRequired!'
								}
							]
						})(
							<Switch
								checkedChildren="Yes"
								unCheckedChildren="No"
								checked={selectedItem.brandRequired}
								onChange={(checked) => {
									onFieldValueChange({
										brandRequired : checked
									});
								}}
							/>
						)}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Allow As Optional">
						{getFieldDecorator('isOptional', {
							initialValue : selectedItem.active,
							rules : [
								{
									required : true,
									message : 'Please select if it is optional item!'
								}
							]
						})(
							<Switch
								checkedChildren="Yes"
								unCheckedChildren="No"
								checked={selectedItem.isOptional}
								onChange={(checked) => {
									onFieldValueChange({
										isOptional : checked
									});
								}}
							/>
						)}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Possible OK Reasons">
						{getFieldDecorator('possibleOkReasons', {
							initialValue : selectedItem.possibleOkReasons,
							rules : [
								{
									required : true,
									message : 'Please input OK Reasons!'
								}
							]
						})(this.renderReasons('possibleOkReasons'))}
					</Form.Item>

					{/* <Form.Item {...formItemLayout} label="Possible NOT OK Reasons">
						{getFieldDecorator('possibleNotOkReasons', {
							initialValue : selectedItem.possibleNotOkReasons,
							rules : [
								{
									required : true,
									message : 'Please input NOT OK Reasons!'
								}
							]
						})(this.renderReasons('possibleNotOkReasons'))}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Possible NOT OK Sub-Reasons">
						{getFieldDecorator('possibleNotOkSubReasons', {
							initialValue : selectedItem.possibleNotOkSubReasons,
							rules : [
								{
									required : true,
									message : 'Please input NOT OK sub-reasonss!'
								}
							]
						})(this.renderReasons('possibleNotOkSubReasons'))}
					</Form.Item> */}

					<Form.Item {...formItemLayout} label="OK Default Reason">
						{getFieldDecorator('okDefaultReason', {
							initialValue : selectedItem.okDefaultReason,
							rules : [
								{
									required : true,
									message : 'Please input OK Reasons!'
								}
							]
						})(
							<Select
								style={{ width : '100%' }}
								placeholder="Please select"
								onChange={(e) => {
									onFieldValueChange({
										okDefaultReason : e
									});
								}}
							>
								{selectedItem.possibleOkReasons.map(item => (
									<Option key={item}>{item}</Option>
								))}
							</Select>
						)}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Not OK Default Reason">
						{getFieldDecorator('notOkDefaultReason', {
							initialValue : selectedItem.notOkDefaultReason,
							rules : [
								{
									required : true,
									message : 'Please input Not OK Reasons!'
								}
							]
						})(
							<Select
								style={{ width : '100%' }}
								placeholder="Please select"
								onChange={(e) => {
									onFieldValueChange({
										notOkDefaultReason : e
									});
								}}
							>
								{possibleNotReason.map(item => (
									<Option key={item}>{item}</Option>
								))}
							</Select>
						)}
					</Form.Item>

					{/* <Form.Item {...formItemLayout} label="Not OK Default Reason">
						{getFieldDecorator('notOkDefaultSubReason', {
							initialValue : selectedItem.notOkDefaultSubReason,
							rules : [
								{
									required : true,
									message : 'Please input OK Reasons!'
								}
							]
						})(this.renderDefaultReason('notOkDefaultSubReason'))}
					</Form.Item> */}

					<Form.Item {...formItemLayout} label="Active">
						{getFieldDecorator('active', {
							initialValue : selectedItem.active,
							rules : [
								{
									required : true,
									message : 'Please select active!'
								}
							]
						})(
							<Switch
								checkedChildren="Yes"
								unCheckedChildren="No"
								checked={selectedItem.active}
								onChange={(checked) => {
									onFieldValueChange({
										active : checked
									});
								}}
							/>
						)}
					</Form.Item>

					{/* <Form.Item {...formItemLayout} label="Service Provider">
						{getFieldDecorator('serviceProvider', {
							initialValue :
								selectedItem.serviceProvider
								&& selectedItem.serviceProvider.type,
							rules : [
								{
									required : true,
									message : 'Please input Service Provider!'
								}
							]
						})(
							<Select
								style={{ width : '100%' }}
								placeholder="Please select"
								onChange={(e) => {
									onFieldValueChange({
										serviceProvider : {
											type : e
										}
									});
								}}
							>
								{enums.SERVICE_PROVIDER_TYPES.map(item => (
									<Option key={item}>{item}</Option>
								))}
							</Select>
						)}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Implication">
						{getFieldDecorator('implication', {
							initialValue : selectedItem.implication,
							rules : [
								{
									required : true,
									message : 'Please input Implication!'
								}
							]
						})(
							<Select
								style={{ width : '100%' }}
								placeholder="Please select"
								onChange={(e) => {
									onFieldValueChange({
										implication : e
									});
								}}
							>
								{enums.IMPLICATION.map(item => (
									<Option key={item}>{item}</Option>
								))}
							</Select>
						)}
					</Form.Item> */}

					<h3
						style={{
							fontSize : '14px',
							color : 'rgba(0, 0, 0, 0.85)'
						}}
					>
						Mapped Not Ok Reasons:
					</h3>

					{mappedNotOKReasons.length > 0
						&& _.map(mappedNotOKReasons, (mappedReason, index) => this.renderMappedReasons(mappedReason, index))}
					<br />
					<Button
						type="dashed"
						onClick={this.onAddReason}
						style={{ width : '60%' }}
					>
						<Icon type="plus" /> Add Mapped Not Ok Reason
					</Button>
					<br />

					<Form.Item>
						<Button type="primary" htmlType="submit" block loading={loading}>
							{selectedItem._id ? 'Save' : 'Create'}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}

const ItemAddEditModalForm = Form.create()(ItemAddEditModal);
export default ItemAddEditModalForm;
