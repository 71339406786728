import React from 'react';
import { Tag, Button } from 'antd';
import _ from 'lodash';
export function getInspectionRoomItemsHeaders(onImageViewClick) {
	return [
		{
			title : 'Name',
			dataIndex : 'name'
		},
		{
			title : 'Action',
			dataIndex : 'action',
			render : (text) => {
				let color = 'orange';

				if (text === 'OK') {
					color = 'green';
				}

				if (text === 'NOT_OK' || text === 'MISSING') {
					color = 'red';
				}

				if (text === 'NOT_APPLICABLE') {
					color = 'gray';
				}

				return <Tag color={color}>{text || 'In Progress'}</Tag>;
			}
		},
		{
			title : 'Reason / Subreason',
			dataIndex : 'reason',
			render : (text, record) => {
				let string = '';
				
				if(record.version === 1){
					let reasonList = _.filter(record.mappedNotOKReasons, mappedNotOKReason => {
						return record.reasons.indexOf(mappedNotOKReason.reason) !== -1;
					})
					if (record.reasons.length) {
						string += record.reasons.join(', ');
					}

					const textSubReasons = _.map(reasonList, reason => {
						return reason.subReason.replace("Needs","");
					}).join(',');

					string += ` and needs ${textSubReasons}`

				}else{
					if (record.reasons.length) {
						string += record.reasons.join(', ');
					}

					if (record.subReasons.length) {
						string += ` And need ${record.subReasons.join(', ')}`;
					}
				}

				return <span>{string}</span>;
			}
		},
		{
			title : 'Show Images',
			dataIndex : 'attachments',
			render : (text, item) => {
				const attachments = item.attachments.filter(
					attachment => !attachment.action || attachment.action === item.action || !item.action
				);

				if (attachments.length) {
					return (
						<Button
							onClick={() => {
								onImageViewClick(item);
							}}
						>
							{attachments.length} Images
						</Button>
					);
				}

				return <span>Not Available</span>;
			}
		}
	];
}
