import React, { Component } from 'react';
import { Row, Col, Tag } from 'antd';
import basicStyle from '../../../settings/basicStyle';
import OrderDetailWrapper from '../styles/order-detail.style';

import * as InventoryUtils from '../../../utils/inventory';

const { rowStyle, colStyle, gutter } = basicStyle;

class OrderDetailOther extends Component {
	render() {
		const { selectedOrder, onAddressChangeClick } = this.props;
		if (!selectedOrder) {
			return null;
		}

		return (
			<OrderDetailWrapper>
				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Owner Name</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<span>{selectedOrder.ownerName || selectedOrder.owner.name}</span>
					</Col>
				</Row>
				<hr />

				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Property Type</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<span> {selectedOrder.property.subType}</span>
						{selectedOrder.propertyBuyType && <span style={{marginLeft: '1em'}}>({selectedOrder.propertyBuyType})</span>}
						{selectedOrder.inspectionType && <span style={{marginLeft: '1em'}}>({selectedOrder.inspectionType})</span>}
					</Col>
				</Row>
				<hr />
				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Num Bedrooms</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<span> {selectedOrder.property.info.num_bedroom}</span>
					</Col>
				</Row>
				<hr />
				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>Num Bathrooms</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<span> {selectedOrder.property.info.num_bathroom}</span>
					</Col>
				</Row>
				<hr />
				
				<Row style={rowStyle} gutter={gutter}>
					<Col xs={24} md={8} style={colStyle} className="title">
						<span>
							Address{' '}
							<Tag color="red" onClick={onAddressChangeClick}>
								{' '}
								Change{' '}
							</Tag>{' '}
						</span>
					</Col>
					<Col xs={24} md={16} style={colStyle} className="body">
						<span>
							{InventoryUtils.getFormattedAddress(selectedOrder.address)}
						</span>
					</Col>
				</Row>
			</OrderDetailWrapper>
		);
	}
}

export default OrderDetailOther;
