import React, { Component } from 'react';
import { Form, Modal, Input, Switch, Button, Select } from 'antd';

const { Option } = Select;

const formItemLayout = {
	labelCol : {
		xs : { span : 24 },
		sm : { span : 8 }
	},
	wrapperCol : {
		xs : { span : 24 },
		sm : { span : 16 }
	}
};

class ItemAddEditModal extends Component {
	onSubmit = (e) => {
		const { form, onSubmit } = this.props;
		if (e) {
			e.preventDefault();
		}

		form.validateFieldsAndScroll((err) => {
			if (!err) {
				onSubmit();
			}
		});
	};

	renderCategories = () => {
		const { categories, onFieldValueChange } = this.props;

		return (
			<Select
				mode="multiple"
				style={{ width : '100%' }}
				placeholder="Please select"
				onChange={(e) => {
					onFieldValueChange({ categories : e });
				}}
				filterOption={(input, option) => !input
					|| option.props.children.toLowerCase().indexOf(input.toLowerCase())
						!== -1
				}
			>
				{categories.map(category => (
					<Option key={category._id}>{category.name}</Option>
				))}
			</Select>
		);
	};

	renderRoomTypes = () => {
		const { roomTypes, onFieldValueChange } = this.props;

		return (
			<Select
				style={{ width : '100%' }}
				placeholder="Please select"
				onChange={(e) => {
					onFieldValueChange({ type : e });
				}}
			>
				{roomTypes.map(type => (
					<Option key={type}>{type.toUpperCase()}</Option>
				))}
			</Select>
		);
	};

	render() {
		const {
			form,
			selectedRoom,
			onCancel,
			onFieldValueChange,
			loading
		} = this.props;
		const { getFieldDecorator } = form;

		return (
			<Modal
				title={selectedRoom._id ? 'Edit Room' : 'Add New Room'}
				visible={true}
				onCancel={onCancel}
				footer={null}
			>
				<Form onSubmit={this.onSubmit}>
					<Form.Item {...formItemLayout} label="Name">
						{getFieldDecorator('name', {
							initialValue : selectedRoom.name,
							rules : [
								{
									required : true,
									message : 'Please input name!'
								}
							]
						})(
							<Input
								id="name"
								name="name"
								placeholder="Hall/Bedroom"
								onChange={(e) => {
									onFieldValueChange({
										name : e.target.value
									});
								}}
							/>
						)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Description">
						{getFieldDecorator('description', {
							initialValue : selectedRoom.description,
							rules : [
								{
									required : true,
									message : 'Please input description!'
								}
							]
						})(
							<Input
								id="description"
								name="description"
								placeholder="All plumbing items group"
								onChange={(e) => {
									onFieldValueChange({
										description : e.target.value
									});
								}}
							/>
						)}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Room Type">
						{getFieldDecorator('type', {
							initialValue : selectedRoom.type,
							rules : [
								{
									required : true,
									message : 'Please input room type!'
								}
							]
						})(this.renderRoomTypes())}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Categories">
						{getFieldDecorator('categories', {
							initialValue : selectedRoom.categories,
							rules : [
								{
									required : true,
									message : 'Please input categories!'
								}
							]
						})(this.renderCategories())}
					</Form.Item>

					<Form.Item {...formItemLayout} label="Active">
						{getFieldDecorator('active', {
							initialValue : selectedRoom.active,
							rules : [
								{
									required : true,
									message : 'Please select active!'
								}
							]
						})(
							<Switch
								checkedChildren="Yes"
								unCheckedChildren="No"
								checked={selectedRoom.active}
								onChange={(checked) => {
									onFieldValueChange({
										active : checked
									});
								}}
							/>
						)}
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" block loading={loading}>
							{selectedRoom._id ? 'Save' : 'Create'}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}

const ItemAddEditModalForm = Form.create()(ItemAddEditModal);
export default ItemAddEditModalForm;
