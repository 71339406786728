import React from 'react';
import { Tag } from 'antd';
import * as changeCase from 'change-case';

export default (props) => {
	const { text } = props;
	let color = 'green';

	if (['processing', 'in_progress', 'pending'].indexOf(text) !== -1) {
		color = 'orange';
	}

	if (['disabled', 'inactive'].indexOf(text) !== -1) {
		color = 'red';
	}

	return <Tag color={color}> {changeCase.titleCase(text)} </Tag>;
};
