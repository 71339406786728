import * as ActionTypes from '../constants/actions';

const defaultState = {
	allUsers : {
		users : [],
		count : 0,
		limit : 10,
		page : 0
	}
};

export default function setting(state = defaultState, action) {
	switch (action.type) {
		case ActionTypes.USER.SET_ALL_USERS: {
			return {
				...state,
				allUsers : action.allUsers
			};
		}
		default: {
			return state;
		}
	}
}
