import React, { Component } from 'react';
import { Table, Row, Col } from 'antd';
import connect from 'react-redux/es/connect/connect';
import _ from 'lodash';
import numeral from 'numeral';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';

//Actions
import * as OrderActions from '../../../actions/order';

//Utils
import * as InspectionSnapshotTableHeaders from '../../../utils/table-headers/inspection-snapshot-list';
import * as InventoryUtils from '../../../utils/inventory';

import LayoutWrapper from '../../../components/utility/layoutWrapper';
import InspectionDetailPageWrapper from '../styles/inspection-detail-page.style';
import PageHeader from '../../../components/utility/pageHeader';
import InspectionStepsDropdown from '../../../components/order/inspection/inspection-steps-dropdown';
import Skeleton from 'antd/lib/skeleton';
import Box from '../../../components/utility/box';
import basicStyle from '../../../settings/basicStyle';

const { rowStyle, colStyle } = basicStyle;

class InspectionSnapshotPage extends Component {
	state = {};

	componentDidMount() {
		this.fetchOrder();
	}

	fetchOrder = (callback) => {
		const { match, dispatch } = this.props;
		dispatch(
			OrderActions.fetchOrderById(match.params.orderId, {}, (order) => {
				const inspection = order.inspections[0];
				this.setState({
					selectedOrder : order,
					allCountSnapshot : this.getAllCountSnapshot(inspection),
					workingNonWorkingSnapshot : this.getWorkingNonWorkingSnapshot(
						inspection
					)
				});

				if (callback) {
					callback(order);
				}
			})
		);
	};

	getWorkingNonWorkingSnapshot = (selectedInspection) => {
		const blockIdMap = {};

		selectedInspection.details.blocks.forEach((block) => {
			if (!blockIdMap[block._id]) {
				blockIdMap[block._id] = {
					nonWorkingCount : 0,
					totalCount : 0,
					name : block.name,
					displayName : block.rooms[0].name,
					maxCategories : block.rooms[0].categories.length
				};
			}

			block.rooms.forEach((room) => {
				if (blockIdMap[block._id].maxCategories < room.categories.length) {
					blockIdMap[block._id].maxCategories = room.categories.length;
					blockIdMap[block._id].displayName = room.name;
				}

				room.categories.forEach((category) => {
					category.items.forEach((item) => {
						if (item.action && item.action !== 'NOT_APPLICABLE') {
							blockIdMap[block._id].totalCount += 1;
							blockIdMap[block._id].nonWorkingCount
								+= item.action === 'NOT_OK' || item.action === 'MISSING' ? 1 : 0;
						}
					});
				});
			});
		});

		//It's array
		//*
		// {
		// name: 'Hall + Balcony'
		// displayName: Hall,
		// nonWorkingCount: 0,
		// totalCount: 2
		// maxCategories: Max categories in Hall
		// }
		// *//
		const snapshot = Object.keys(blockIdMap).map(key => ({
			...blockIdMap[key]
		}));

		const overall = {
			name : 'Overall',
			displayName : 'Overall'
		};

		overall.nonWorkingCount = snapshot.reduce(
			(sum, item) => sum + item.nonWorkingCount,
			0
		);

		overall.totalCount = snapshot.reduce(
			(sum, item) => sum + item.totalCount,
			0
		);

		snapshot.push(overall);

		const nonWorkingObj = {
			title : 'Non Working Points'
		};

		const totalCheckPointObj = {
			title : 'Total Check Points'
		};

		const ratingObj = {
			title : 'Rating'
		};

		snapshot.forEach((input) => {
			const name = input.displayName; //Need some input when to use displayName, it's replacing common name like bedroom.
			nonWorkingObj[name] = input.nonWorkingCount;
			totalCheckPointObj[name] = input.totalCount;
			ratingObj[name] = numeral(input.totalCount - input.nonWorkingCount)
				.divide(input.totalCount)
				.multiply(100)
				.value();
		});

		return [nonWorkingObj, totalCheckPointObj, ratingObj];
	};

	getAllCountSnapshot = (selectedInspection) => {
		const allCountItemsNameMap = {};

		selectedInspection.details.blocks.forEach((block) => {
			block.rooms.forEach((room) => {
				room.categories.forEach((category) => {
					category.items.forEach((item) => {
						if (item.countRequired) {
							if (!allCountItemsNameMap[item.name]) {
								allCountItemsNameMap[item.name] = {
									count : 0
								};
							}

							allCountItemsNameMap[item.name].count += item.count;
						}
					});
				});
			});
		});

		const finalItems = Object.keys(allCountItemsNameMap).map(key => ({
			name : key,
			count : allCountItemsNameMap[key].count
		}));

		console.log('Total items count ', finalItems);
		finalItems.sort((a, b) => a.name.localeCompare(b.name));

		const chunks = _.chunk(finalItems, finalItems.length / 5);

		return chunks;
	};

	renderAllCountSnapshotTable = () => {
		const { allCountSnapshot = [] } = this.state;

		return (
			<Row style={rowStyle}>
				{allCountSnapshot.map((chunk, index) => (
					<Col lg={4} style={colStyle}>
						<Table
							key={`all-count-table-chunk-${index}`}
							columns={InspectionSnapshotTableHeaders.getAllCountHeaders()}
							dataSource={chunk}
							pagination={false}
						/>
					</Col>
				))}
			</Row>
		);
	};

	renderWorkingNonWorkingSnapshotTable = () => {
		const { workingNonWorkingSnapshot } = this.state;
		const headers = Object.keys(workingNonWorkingSnapshot[0]).filter(
			input => input !== 'title'
		);

		return (
			<div className="working-nonworking-table-container">
				<Table
					scroll={{ x : 1200 }}
					columns={InspectionSnapshotTableHeaders.getWorkingNonWorkingHeaders(
						headers
					)}
					dataSource={workingNonWorkingSnapshot}
					pagination={false}
				/>
			</div>
		);
	};

	renderBarchart = () => {
		const { workingNonWorkingSnapshot } = this.state;
		const rating = _.find(
			workingNonWorkingSnapshot,
			input => input.title === 'Rating'
		);

		const data = Object.keys(rating)
			.filter(key => key !== 'title')
			.map(key => ({
				name : key,
				value : numeral(parseFloat(rating[key]).toFixed(2)).value()
			}));

		return (
			<BarChart
				width={data.length * 90}
				height={450}
				data={data}
				margin={{ top : 20, bottom : 150 }}
			>
				<XAxis
					dataKey="name"
					interval={0}
					width={30}
					tick={({ x, y, payload }) => (
						<g transform={`translate(${x},${y})`}>
							<text
								x={0}
								y={0}
								dx={0}
								textAnchor="end"
								fill="#666"
								transform="rotate(-90)"
							>
								{InventoryUtils.getDisplayBlockName(payload.value)}
							</text>
						</g>
					)}
				/>
				<YAxis />
				<Tooltip />
				<Bar
					dataKey="value"
					fill="#8884d8"
					barSize={50}
					label={({ x, y, fill, value }) => (
						<text
							x={x}
							y={y}
							dy={-4}
							dx={20}
							fontSize="16"
							fill={fill}
							textAnchor="middle"
						>
							{value}%
						</text>
					)}
				/>
			</BarChart>
		);
	};

	render() {
		const { match, history, order } = this.props;
		const { selectedOrder } = this.state;

		return (
			<LayoutWrapper>
				<InspectionDetailPageWrapper>
					<div className="snapshot-container">
						<PageHeader>Inspection Snapshot</PageHeader>
						<InspectionStepsDropdown
							currentStep="snapshot"
							orderId={match.params.orderId}
							inspectionId={match.params.inspectionId}
							history={history}
							selectedOrder={order.selectedOrder}
						/>

						<div>
							<Box title="Overall Snapshot">
								<Skeleton loading={!selectedOrder} active>
									{selectedOrder && this.renderAllCountSnapshotTable()}
								</Skeleton>
							</Box>
						</div>

						<div>
							<Box title="Percentage Snapshot">
								<Skeleton loading={!selectedOrder} active>
									{selectedOrder && this.renderWorkingNonWorkingSnapshotTable()}
								</Skeleton>
							</Box>
						</div>

						<div>
							<Box title="Ranking">
								<Skeleton loading={!selectedOrder} active>
									{selectedOrder && this.renderBarchart()}
								</Skeleton>
							</Box>
						</div>
					</div>
				</InspectionDetailPageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(InspectionSnapshotPage);
