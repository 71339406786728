import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Table } from 'antd';
import _ from 'lodash';

import LayoutWrapper from '../../components/utility/layoutWrapper';
import PageHeader from '../../components/utility/pageHeader';
import Box from '../../components/utility/box';
import OrderSearchBox from '../../components/order/order-search';

import * as OrderHeaders from '../../utils/table-headers/order-list';
import UserListPageWrapper from './styles/order-list-page.style';
//Actions
import * as OrderActions from '../../actions/order';

class OrderListPage extends Component {
	state = {
		ordersLoading : false,
		search : {}
	};

	componentDidMount() {
		this.fetchOrders(0);
	}

	fetchOrders = (page) => {
		this.setState({ ordersLoading : true });
		const { dispatch } = this.props;
		const { search } = this.state;

		dispatch(
			OrderActions.fetchOrders({ page, limit : 10, ...search }, () => {
				this.setState({ ordersLoading : false });
				console.log('All orders fetched');
			})
		);
	};

	onSearchValueChange = (obj) => {
		const { search } = this.state;
		this.setState({ search : _.merge({}, search, obj) });
	};

	onSearchSubmit = () => {
		this.fetchOrders(0);
	};

	render() {
		const { ordersLoading, search } = this.state;
		const { order } = this.props;
		const { orders, count, limit, page } = order.allOrders;

		return (
			<LayoutWrapper>
				<UserListPageWrapper>
					<PageHeader>Manage Orders</PageHeader>
					<div style={{ marginBottom : '1em' }}>
						<Box title="Search">
							<OrderSearchBox
								{...search}
								onSearchValueChange={this.onSearchValueChange}
								onSearchSubmit={this.onSearchSubmit}
							/>
						</Box>
					</div>

					<Box>
						<Table
							scroll={{ x : 1300 }}
							columns={OrderHeaders.getHeaders()}
							dataSource={orders}
							loading={ordersLoading}
							pagination={{
								current : page + 1,
								pageSize : limit,
								total : count
							}}
							onChange={(currentPagination) => {
								this.fetchOrders(currentPagination.current - 1);
							}}
						/>
					</Box>
				</UserListPageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(OrderListPage);
