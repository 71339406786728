import React from 'react';
import { LayoutContentWrapper } from './layoutWrapper.style';

export default (props) => {
	const { className, children } = props;
	return (
		<LayoutContentWrapper
			className={
				className != null
					? `${className} isoLayoutContentWrapper`
					: 'isoLayoutContentWrapper'
			}
			{...props}
		>
			{children}
		</LayoutContentWrapper>
	);
};
