import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const UserSearchWrapper = styled.div`
	width: 100%;
	.search-btn {
		display: flex;
		align-items: flex-end;
	}
`;

export default WithDirection(UserSearchWrapper);
