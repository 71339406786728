import React, { Component } from 'react';
import {
	Col,
	Form,
	Row,
	Upload,
	Card,
	message,
	Button,
	Icon,
	Popconfirm
} from 'antd';
import connect from 'react-redux/es/connect/connect';
import _ from 'lodash';
import FileSaver from 'file-saver';

//Actions
import * as OrderActions from '../../actions/order';
import * as UserActions from '../../actions/user';
import * as SettingActions from '../../actions/setting';

//Apis
import * as OrderApis from '../../apis/order';

import LayoutWrapper from '../../components/utility/layoutWrapper';
import OrderDetailPageWrapper from './styles/order-detail-page.style';
import PageHeader from '../../components/utility/pageHeader';
import basicStyle from '../../settings/basicStyle';
import OrderDetailOwnerProperty from '../../components/order/order-detail/order-detail-owner-property';
import OrderOtherDetail from '../../components/order/order-detail/order-detail-other';
import AddressModal from '../../components/inspaccoUiElements/address-edit-create-modal';
import OrderStatusSteps from '../../components/order/order-detail/order-status-steps';
import BrokerListModal from '../../components/user/broker-list-modal';

const { rowStyle, colStyle, gutter } = basicStyle;

class OrderDetailPage extends Component {
	state = {
		showAddressModal : false,
		showBrokerListModal : false,
		reportUploading : false,
		completeOrderInProgress : false
	};

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(OrderActions.setSelectedOrder(undefined));
		this.fetchOrder();
		this.fetchInspectors();
		this.fetchEnums();
	}

	fetchEnums = () => {
		const { dispatch } = this.props;
		dispatch(SettingActions.fetchEnums());
	};

	fetchInspectors = () => {
		const { dispatch } = this.props;
		dispatch(UserActions.fetchUsers({ role : 'inspector', active : true }));
	};

	fetchOrder = (callback) => {
		const { match, dispatch } = this.props;
		dispatch(
			OrderActions.fetchOrderById(match.params.orderId, {}, (data) => {
				this.fetchCityAreas(data.address.city);
				dispatch(SettingActions.fetchCities());
				if (callback) {
					callback(data);
				}
			})
		);
	};

	onAddressChangeClick = () => {
		console.log('Address change clicked');
		const { order } = this.props;
		const { selectedOrder } = order;

		this.setState({
			showAddressModal : true,
			selectedAddress : selectedOrder.address
		});
	};

	fetchCityAreas = (city) => {
		console.log('City changed ', city);
		const { dispatch } = this.props;
		dispatch(SettingActions.fetchCityAreas(city));
	};

	onAddressValueChange = (obj) => {
		const { selectedAddress } = _.cloneDeep(this.state);
		this.setState({ selectedAddress : _.merge({}, selectedAddress, obj) });
		if (obj.city) {
			this.fetchCityAreas(obj.city);
		}
	};

	updateOrderDetails = async (body) => {
		const { order } = this.props;
		try {
			this.setState({ updateInProgress : true });
			await OrderApis.updateOrder(order.selectedOrder._id, body);
			return this.fetchOrder(() => {
				message.success('Order updated!');
				this.setState({
					updateInProgress : false,
					address : undefined,
					showAddressModal : false
				});
			});
		} catch (err) {
			console.log(err);
			this.setState({ updateInProgress : false });
		}
	};

	onFieldValueChange = (obj) => {
		const { order, dispatch } = this.props;
		const selectedOrder = _.merge({}, order.selectedOrder, obj);
		dispatch(OrderActions.setSelectedOrder(selectedOrder));
	};

	onAddressChangeSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}

		const { form } = this.props;
		const { selectedAddress } = this.state;

		form.validateFieldsAndScroll(async (err) => {
			if (err) {
				message.error('Please fill required details!');
				return;
			}

			await this.updateOrderDetails({ address : selectedAddress });
		});
	};

	onInspectorAssign = async (inspector) => {
		const { order } = this.props;
		try {
			this.setState({ updateInProgress : true });
			await OrderApis.assignInspector(order.selectedOrder._id, inspector);
			this.fetchOrder(() => {
				this.setState({ updateInProgress : false });
			});
			message.success('Inspector changed!');
		} catch (err) {
			console.log(err);
			this.setState({ updateInProgress : false });
		}
	};

	onShowInspectionDetails = () => {
		const { order, history } = this.props;
		const { selectedOrder } = order;

		history.push(
			`/orders/${selectedOrder._id}/inspections/${selectedOrder.inspections[0]._id}/summary`
		);
	};

	onBrokerChangeClick = () => {
		this.setState({ showBrokerListModal : true });
	};

	onBrokerAssign = (user) => {
		this.setState({
			showBrokerListModal : false
		});

		this.updateOrderDetails({
			leadGenerator : user._id,
			leadGeneratorType : 'broker'
		});

		this.fetchInspectors();
	};

	onReportUpload = async (info) => {
		const { file } = info;
		const { order } = this.props;
		const { selectedOrder } = order;

		const formData = new FormData();
		formData.append('file', file);
		formData.append(
			'folder',
			`orders/${selectedOrder._id}/inspection-reports/`
		);

		try {
			this.setState({ reportUploading : true });
			await OrderApis.uploadReport(
				selectedOrder._id,
				selectedOrder.inspections[0]._id,
				formData
			);
			this.setState({ reportUploading : false });
			message.success('Report uploaded!');
			this.fetchOrder();
		} catch (err) {
			this.setState({ reportUploading : false });
		}

		return false;
	};

	completeOrderClicked = async () => {
		this.setState({ completeOrderInProgress : true });
		const { order } = this.props;

		try {
			await OrderApis.finishOrder(order.selectedOrder._id);
			this.fetchOrder();
			message.success('Status changed!');
			this.setState({ completeOrderInProgress : false });
		} catch (err) {
			console.log(err);
			this.setState({ completeOrderInProgress : false });
		}
	};

	generateDocReport = async () => {
		this.setState({ isDocReportInProgress : true });
		const { order } = this.props;
		const { selectedOrder } = order;
		const selectedInspection = selectedOrder && selectedOrder.inspections[0];
		if (!selectedInspection) {
			return message.error('Inspection Not Found');
		}

		try {
			const response = await OrderApis.generateDocReport(
				selectedOrder._id,
				selectedInspection._id
			);
			FileSaver.saveAs(
				new Blob([response]),
				`${selectedOrder.ownerName
					|| selectedOrder.owner.name}-inspection-report.docx`
			);
			this.setState({ isDocReportInProgress : false });
		} catch (err) {
			console.log(err);
			this.setState({ isDocReportInProgress : false });
		}
	};

	render() {
		const { order, form, user, setting } = this.props;
		const {
			showAddressModal,
			selectedAddress,
			updateInProgress,
			showBrokerListModal,
			reportUploading,
			completeOrderInProgress,
			isDocReportInProgress
		} = this.state;

		const { selectedOrder } = order;
		const { cities, areas } = setting;
		const allowUpload =			!reportUploading
			&& selectedOrder
			&& selectedOrder.status === 'pending_report';
		const selectedInspection = selectedOrder && selectedOrder.inspections[0];
		const oldReportUrl =			selectedInspection
			&& selectedInspection.report
			&& selectedInspection.report.url;
		const enableCompleteOrder =			oldReportUrl && selectedOrder.status === 'pending_report';

		return (
			<LayoutWrapper>
				<OrderDetailPageWrapper>
					<PageHeader>Order Details</PageHeader>

					<OrderStatusSteps selectedOrder={selectedOrder} />

					<Row style={rowStyle} gutter={gutter}>
						<Col md={12} xs={24} style={colStyle}>
							<Card title="Owner and Property Details" loading={!selectedOrder}>
								<OrderDetailOwnerProperty
									selectedOrder={selectedOrder}
									onAddressChangeClick={this.onAddressChangeClick}
								/>
							</Card>
						</Col>
						<Col md={12} xs={24} style={colStyle}>
							<Card title="Other Details" loading={!selectedOrder}>
								<OrderOtherDetail
									selectedOrder={selectedOrder}
									onInspectorAssign={this.onInspectorAssign}
									inspectors={user.allUsers.users}
									updateInProgress={updateInProgress}
									updateOrderDetails={this.updateOrderDetails}
									onFieldValueChange={this.onFieldValueChange}
									onShowInspectionDetails={this.onShowInspectionDetails}
									onBrokerChangeClick={this.onBrokerChangeClick}
								/>
							</Card>
						</Col>
					</Row>

					<Row style={rowStyle} gutter={gutter}>
						<Col xs={24} style={colStyle}>
							<Card
								title="Upload Report and Change Status"
								loading={!selectedOrder}
							>
								<Row>
									<Col lg={6} xs={24} style={colStyle}>
										<Upload
											beforeUpload={() => false}
											disabled={!allowUpload}
											onChange={this.onReportUpload}
											showUploadList={false}
										>
											<Button loading={reportUploading} disabled={!allowUpload}>
												<Icon type="upload" /> Click to Upload Report
											</Button>
										</Upload>
									</Col>
									<Col lg={6} xs={24} style={colStyle}>
										<Button
											type="primary"
											shape="round"
											icon="download"
											size="default"
											disabled={!oldReportUrl}
										>
											<a
												href={oldReportUrl || '#'}
												target="_blank"
												style={{ color : 'white' }}
												rel="noopener noreferrer"
											>
												Download Old Report
											</a>
										</Button>
									</Col>
									<Col lg={6} xs={24} style={colStyle}>
										<Popconfirm
											title="Change status to completed？"
											okText="Yes"
											cancelText="No"
											onConfirm={this.completeOrderClicked}
											disabled={!enableCompleteOrder}
										>
											<Button
												type="primary"
												disabled={!enableCompleteOrder}
												loading={completeOrderInProgress}
											>
												Finish Order
											</Button>
										</Popconfirm>
									</Col>
									<Col lg={6} xs={24} style={colStyle}>
										<Button
											type="primary"
											loading={isDocReportInProgress}
											onClick={this.generateDocReport}
											disabled={!selectedInspection}
										>
											Generate Doc Report
										</Button>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</OrderDetailPageWrapper>

				{showAddressModal && (
					<AddressModal
						form={form}
						onAddressChange={this.onAddressValueChange}
						onSubmit={this.onAddressChangeSubmit}
						selectedAddress={selectedAddress}
						showSubmitButton={true}
						cities={cities}
						areas={areas}
						onCancel={() => {
							this.setState({ showAddressModal : false });
						}}
						loading={updateInProgress}
					/>
				)}

				{showBrokerListModal && (
					<BrokerListModal
						onBrokerSelect={this.onBrokerAssign}
						onCancel={() => {
							this.setState({ showBrokerListModal : false });
						}}
					/>
				)}
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

const OrderDetailFormPage = Form.create()(OrderDetailPage);
export default connect(mapStateToProps)(OrderDetailFormPage);
