import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Table, message, Button, Input, Col, Row } from 'antd';

import LayoutWrapper from '../../components/utility/layoutWrapper';
import PageHeader from '../../components/utility/pageHeader';
import Box from '../../components/utility/box';
import * as ItemHeaders from '../../utils/table-headers/item-list';
import ItemAddEditModal from '../../components/inventory/item-add-edit-modal';
import ItemListPageWrapper from './styles/items-list-page.style';
import basicStyle from '../../settings/basicStyle';

//Actions
import * as InventoryActions from '../../actions/inventory';
import * as SettingActions from '../../actions/setting';

//Apis
import * as InventoryApis from '../../apis/inventory';

const { rowStyle, colStyle, gutter } = basicStyle;

class ItemsListPage extends Component {
	state = {
		showAddEditModal : false,
		updateInProgress : false,
		itemsLoading : false
	};

	componentDidMount() {
		this.fetchItems();
		this.fetchEnums();
	}

	fetchItems = () => {
		this.setState({ itemsLoading : true });
		const { dispatch } = this.props;
		dispatch(
			InventoryActions.fetchAllItems({}, () => {
				this.setState({ itemsLoading : false });
				console.log('All items fetched');
			})
		);
	};

	fetchEnums = () => {
		const { dispatch } = this.props;

		dispatch(SettingActions.fetchEnums());
	};

	onItemEditClick = (selectedItem) => {
		this.setState({ selectedItem, showAddEditModal : true });
	};

	onFieldValueChange = (obj) => {
		const { selectedItem } = this.state;
		this.setState({
			selectedItem : Object.assign({}, selectedItem, obj)
		});
	};

	onAddItemClick = () => {
		const defaultItem = {
			active : true,
			countRequired : false,
			brandRequired : false,
			isOptional : true,
			possibleOkReasons : [],
			possibleNotOkReasons : [],
			possibleNotOkSubReasons : []
		};
		this.setState({ selectedItem : defaultItem, showAddEditModal : true });
	};

	onSubmitItem = async () => {
		const { selectedItem } = this.state;
		this.setState({ updateInProgress : true });

		try {
			if (selectedItem._id) {
				await InventoryApis.updateItem(selectedItem);
			} else {
				await InventoryApis.createItem(selectedItem);
			}

			message.success('Item saved!');
			this.setState({ updateInProgress : false, showAddEditModal : false });
			this.fetchItems();
		} catch (err) {
			console.log(err);
			this.setState({ updateInProgress : false });
		}
	};

	render() {
		const {
			showAddEditModal,
			selectedItem,
			updateInProgress,
			itemsLoading,
			searchText
		} = this.state;
		const { inventory, setting } = this.props;

		const items = inventory.allItems.items.filter(
			item => !searchText
				|| item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
		);

		return (
			<LayoutWrapper>
				<ItemListPageWrapper>
					<PageHeader>Manage Items</PageHeader>
					<Box>
						<div className="search-box">
							<Row style={rowStyle} gutter={gutter} justify="start">
								<Col lg={10} xs={20} style={colStyle}>
									<Input
										placeholder="Search Item"
										type="text"
										onChange={(e) => {
											this.setState({ searchText : e.target.value });
										}}
									/>
								</Col>
								<Col lg={14} xs={4} style={colStyle}>
									<Button
										type="primary"
										shape="circle"
										icon="plus"
										onClick={this.onAddItemClick}
									/>
								</Col>
							</Row>
						</div>
						<Table
							scroll={{ x : 1200 }}
							columns={ItemHeaders.getHeaders(this.onItemEditClick)}
							dataSource={items}
							loading={itemsLoading}
						/>
					</Box>
					{showAddEditModal && (
						<ItemAddEditModal
							selectedItem={selectedItem}
							onCancel={() => {
								this.setState({ showAddEditModal : false });
							}}
							onFieldValueChange={this.onFieldValueChange}
							onSubmit={this.onSubmitItem}
							enums={setting.enums}
							loading={updateInProgress}
						/>
					)}
				</ItemListPageWrapper>
			</LayoutWrapper>
		);
	}
}

function mapStateToProps(state) {
	return state;
}

export default connect(mapStateToProps)(ItemsListPage);
