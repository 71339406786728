import React, { Component } from 'react';
import {
	Form,
	Modal,
	Input,
	Switch,
	Button,
	Select,
	Upload,
	Icon,
	Row,
	Col
} from 'antd';
import AddressForm from '../inspaccoUiElements/form/address-form';
import * as UserApis from '../../apis/user';

const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
	labelCol : {
		xs : { span : 24 },
		sm : { span : 8 }
	},
	wrapperCol : {
		xs : { span : 24 },
		sm : { span : 16 }
	}
};

class UserEditModal extends Component {
	state = {
		showAddress : false
	};

	onSubmit = (e) => {
		const { form, onSubmit } = this.props;
		if (e) {
			e.preventDefault();
		}

		form.validateFieldsAndScroll((err) => {
			if (!err) {
				onSubmit();
			}
		});
	};

	renderRoleTypes = () => {
		const { allRoles, onFieldValueChange } = this.props;

		return (
			<Select
				style={{ width : '100%' }}
				placeholder="Please select"
				onChange={(e) => {
					onFieldValueChange({ role : e });
				}}
			>
				{allRoles.map(role => (
					<Option key={role}>{role.toUpperCase()}</Option>
				))}
			</Select>
		);
	};

	onProfileUpload = async (info) => {
		const { file } = info;
		const { onFieldValueChange } = this.props;
		const formData = new FormData();
		formData.append('file', file);
		formData.append('folder', 'logos/brokers');

		try {
			this.setState({ logoUploading : true });
			const response = await UserApis.uploadProfileLogo(formData);
			this.setState({ logoUploading : false });
			onFieldValueChange({ profileUrl : response.data.url });
		} catch (err) {
			this.setState({ logoUploading : false });
		}

		return false;
	};

	render() {
		const {
			form,
			selectedUser,
			onCancel,
			onFieldValueChange,
			onAddressClear,
			loading,
			onAddressChange,
			areas,
			cities
		} = this.props;
		const { showAddress, logoUploading } = this.state;
		const userHasAddress =			selectedUser && selectedUser.address && selectedUser.address._id;

		const { getFieldDecorator } = form;
		const isNew = !selectedUser._id;

		return (
			<Modal
				title={isNew ? 'Add User' : 'Edit User'}
				visible={true}
				onCancel={onCancel}
				footer={null}
			>
				<Form onSubmit={this.onSubmit}>
					<Form.Item {...formItemLayout} label="Name">
						{getFieldDecorator('name', {
							initialValue : selectedUser.name,
							rules : [
								{
									required : true,
									message : 'Please input name!'
								}
							]
						})(
							<Input
								id="name"
								name="name"
								placeholder="Ram Kumar"
								onChange={(e) => {
									onFieldValueChange({
										name : e.target.value
									});
								}}
							/>
						)}
					</Form.Item>

					{isNew && (
						<Form.Item {...formItemLayout} label="Phone">
							{getFieldDecorator('phone', {
								initialValue : selectedUser.phone,
								rules : [
									{
										required : true,
										message : 'Please input 10 digit phone!'
									},
									{
										min : 10,
										message : 'Phone must be min 10 digit'
									},
									{
										max : 10,
										message : 'Phone must be min 10 digit'
									}
								]
							})(
								<Input
									id="phone"
									name="phone"
									placeholder="10 digit phone number"
									onChange={(e) => {
										onFieldValueChange({
											phone : e.target.value
										});
									}}
								/>
							)}
						</Form.Item>
					)}

					{isNew && (
						<Form.Item {...formItemLayout} label="Email">
							{getFieldDecorator('email', {
								initialValue : selectedUser.email,
								rules : [
									{
										type : 'email',
										message : 'The input is not valid E-mail!'
									}
								]
							})(
								<Input
									id="email"
									name="email"
									placeholder="hello@gmail.com"
									onChange={(e) => {
										onFieldValueChange({
											email : e.target.value
										});
									}}
								/>
							)}
						</Form.Item>
					)}

					{isNew && (
						<Form.Item {...formItemLayout} label="Role">
							{getFieldDecorator('role', {
								rules : [
									{
										required : true,
										message : 'Please input role!'
									}
								]
							})(this.renderRoleTypes())}
						</Form.Item>
					)}

					<Form.Item {...formItemLayout} label="Active">
						{getFieldDecorator('active', {
							initialValue : selectedUser.active,
							rules : [
								{
									required : true,
									message : 'Please select active!'
								}
							]
						})(
							<Switch
								checkedChildren="Yes"
								unCheckedChildren="No"
								checked={selectedUser.active}
								onChange={(checked) => {
									onFieldValueChange({
										active : checked
									});
								}}
							/>
						)}
					</Form.Item>

					{selectedUser.role === 'broker' && (
						<Form.Item {...formItemLayout} label="Enable White Label">
							{getFieldDecorator('whiteLabelEnabled', {
								initialValue : selectedUser.whiteLabelEnabled,
								rules : [
									{
										required : true,
										message : 'Please select white label!'
									}
								]
							})(
								<Switch
									checkedChildren="Yes"
									unCheckedChildren="No"
									checked={selectedUser.whiteLabelEnabled}
									onChange={(checked) => {
										onFieldValueChange({
											whiteLabelEnabled : checked
										});
									}}
								/>
							)}
						</Form.Item>
					)}

					{selectedUser.whiteLabelEnabled && (
						<Form.Item {...formItemLayout} label="Business Name">
							{getFieldDecorator('businessName', {
								initialValue : selectedUser.businessName,
								rules : [
									{
										required : true,
										message : 'Please input business name!'
									}
								]
							})(
								<Input
									id="businessName"
									name="businessName"
									placeholder="Prayag Properties"
									onChange={(e) => {
										onFieldValueChange({
											businessName : e.target.value
										});
									}}
								/>
							)}
						</Form.Item>
					)}

					{selectedUser.whiteLabelEnabled && (
						<Form.Item {...formItemLayout} label="Business Tagline">
							{getFieldDecorator('businessTagline', {
								initialValue : selectedUser.businessTagline,
								rules : [
									{
										required : true,
										message : 'Please input business tagline!'
									}
								]
							})(
								<Input
									id="businessTagline"
									name="businessTagline"
									placeholder="We make dream come true..!"
									onChange={(e) => {
										onFieldValueChange({
											businessTagline : e.target.value
										});
									}}
								/>
							)}
						</Form.Item>
					)}

					{selectedUser.whiteLabelEnabled && (
						<Form.Item {...formItemLayout} label="Business Type">
							{getFieldDecorator('businessType', {
								initialValue : selectedUser.businessType,
								rules : [
									{
										required : true,
										message : 'Please input business type!'
									}
								]
							})(
								<Input
									id="businessType"
									name="businessType"
									placeholder="Real estate consultant & property management services."
									onChange={(e) => {
										onFieldValueChange({
											businessType : e.target.value
										});
									}}
								/>
							)}
						</Form.Item>
					)}

					{selectedUser.whiteLabelEnabled && (
						<Form.Item {...formItemLayout} label="Business Services">
							{getFieldDecorator('businessServices', {
								initialValue : selectedUser.businessServices,
								rules : []
							})(
								<TextArea
									id="businessServices"
									name="businessServices"
									placeholder="Long list of services..!"
									rows={5}
									onChange={(e) => {
										onFieldValueChange({
											businessServices : e.target.value
										});
									}}
								/>
							)}
						</Form.Item>
					)}

					<Form.Item {...formItemLayout} label="Add Address">
						{getFieldDecorator('addAddress', {
							initialValue : showAddress || userHasAddress
						})(
							<Switch
								checkedChildren="Yes"
								unCheckedChildren="No"
								checked={showAddress || userHasAddress}
								onChange={(checked) => {
									this.setState({
										showAddress : checked
									});

									if (!userHasAddress && !checked) {
										console.log('Clearing Address');
										onAddressClear();
									}
								}}
							/>
						)}
					</Form.Item>
				</Form>

				{(showAddress || userHasAddress) && (
					<AddressForm
						onAddressChange={onAddressChange}
						areas={areas || []}
						cities={cities || []}
						form={form}
						formItemLayout={formItemLayout}
						selectedAddress={selectedUser.address || {}}
					/>
				)}

				{selectedUser.role === 'broker' && (
					<Row>
						<Col xs={24} sm={8} />
						<Col xs={24} sm={16}>
							<Upload
								beforeUpload={() => false}
								disabled={logoUploading}
								onChange={this.onProfileUpload}
								showUploadList={false}
							>
								{(logoUploading || !selectedUser.profileUrl) && (
									<div
										style={{
											textAlign : 'center',
											padding : '1em',
											margin : '1em',
											border : '1px dashed'
										}}
									>
										<Icon type={logoUploading ? 'loading' : 'plus'} />
										<div className="ant-upload-text">Upload Logo</div>
									</div>
								)}

								{!logoUploading && selectedUser.profileUrl && (
									<div
										style={{
											textAlign : 'center',
											padding : '0.2em',
											margin : '1em',
											border : '1px dashed'
										}}
									>
										<img
											src={selectedUser.profileUrl}
											style={{ width : '100px' }}
											alt="profile"
										/>
									</div>
								)}
							</Upload>
						</Col>
					</Row>
				)}

				<Button type="primary" block loading={loading} onClick={this.onSubmit}>
					{'Save'}
				</Button>
			</Modal>
		);
	}
}

const UserEditModalForm = Form.create()(UserEditModal);
export default UserEditModalForm;
